import './index.css';

import { pageRouter } from '@model/pageModel';
import * as Sentry from '@sentry/react';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import ReactDOM from 'react-dom/client';
import { unstable_HistoryRouter as HistoryRouter } from 'react-router-dom';

import App from './App';

/**
 * @note react-app-rewired은 start 일 때 development, build 일 때 production 이 고정이라 process.env.REACT_APP_ENV 사용
 */
if (process.env.REACT_APP_ENV === 'production') {
  Sentry.init({
    dsn: 'https://50bc26a46730bb40a05bcf82b3e5ae57@o774769.ingest.sentry.io/4506109255155712',
    integrations: [
      new Sentry.Replay({
        networkDetailAllowUrls: [/^https:\/\/api\.standby\.kr/],
        networkRequestHeaders: ['Authorization', 'Accept', 'User-Agent', 'Cache-Control'],
        networkResponseHeaders: ['Content-Type', 'Date'],
      }),
    ],
    environment: process.env.REACT_APP_ENV,
    // Session Replay
    replaysSessionSampleRate: 0, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: process.env.REACT_APP_ENV === 'production' ? 0.1 : 0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
    maskAllText: false,
    maskAllInputs: false,
    blockAllMedia: false,
    ignoreErrors: ['AxiosError', 'IgnoredError'],
    release: `standby-frontend@${process.env.REACT_APP_VERSION}`,
    // 객체 기록시 최대 깊이
    normalizeDepth: 6,
  });
}

async function enableMocking() {
  // mocking 사용하고 싶을때 변경
  const isMockingEnabled = false;

  if (process.env.NODE_ENV !== 'development' || !isMockingEnabled) {
    return;
  }

  const { worker } = await import('./mocks/browser');

  return worker.start({
    onUnhandledRequest: 'bypass',
  });
}

const queryClient = new QueryClient();

const root = ReactDOM.createRoot(document.getElementById('root'));

void enableMocking().then(() =>
  root.render(
    <HistoryRouter history={pageRouter.history}>
      <QueryClientProvider client={queryClient}>
        <App />
        <ReactQueryDevtools initialIsOpen={false} />
      </QueryClientProvider>
    </HistoryRouter>,
  ),
);
