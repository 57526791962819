import type { SelectedAgenda } from '@model/Corp/Agenda/Step/agendaStepModel.interface';
import qst from '@pages/Corp/Agenda/Step/AgendaQuestion/commonQuestion.module.scss';
import type {
  I기존_지점_주소_변경,
  I변경된_기존_지점_주소,
  SelectableMetropolitanType,
} from '@src/service/Corp/Agenda/agendaService.interface';
import CheckBox from '@standby/common-ui/components/atoms/Input/CheckBox/CheckBox';
import Select from '@standby/common-ui/components/atoms/Input/Select/Select';
import Error from '@standby/common-ui/components/molecules/ErrorValidation/ErrorValidation';
import { runInAction } from 'mobx';
import { observer, useLocalObservable } from 'mobx-react';
import { useEffect } from 'react';

import st from './style.module.scss';

interface Props {
  agenda: SelectedAgenda<I기존_지점_주소_변경>;
  agendaIndex: number;
}

interface Mob {
  branchAddresses: string[];
  selectedAddresses: I변경된_기존_지점_주소[];
  selectableMetropolitanTypes: SelectableMetropolitanType[];
  checkSelected(address: string): boolean;
  selectAddress(address: string): void;
  getSelectedAddress(address: string): I변경된_기존_지점_주소 | undefined;
  selectSidoByAddress(address: string, sido: string): void;
  selectSigunguByAddress(address: string, sigungu: string): void;
}

const UpdateBranchAddress = observer(function UpdateBranchAddress(props: Props) {
  const {
    agenda: {
      agendaDetail: { branchAddresses, selectedAddresses, selectableMetropolitanTypes },
    },
  } = props;

  const mob = useLocalObservable<Mob>(() => ({
    branchAddresses: [],
    selectedAddresses: [],
    selectableMetropolitanTypes: [],
    checkSelected(address: string) {
      return this.selectedAddresses.some((selectedAddress) => selectedAddress.address === address);
    },
    selectAddress(address: string) {
      runInAction(() => {
        if (this.checkSelected(address)) {
          this.selectedAddresses = this.selectedAddresses.filter(
            (selectedAddress) => selectedAddress.address !== address,
          );

          return;
        }

        this.selectedAddresses.push({
          address,
          metropolitanType: '',
          sigungu: '',
        });

        this.selectedAddresses.sort(
          (a, b) => this.branchAddresses.indexOf(a.address) - this.branchAddresses.indexOf(b.address),
        );
      });
    },
    getSelectedAddress(address: string): I변경된_기존_지점_주소 | undefined {
      return this.selectedAddresses.find((selectedAddress) => selectedAddress.address === address);
    },
    selectSidoByAddress(address: string, sido: string) {
      const targetAddress = this.getSelectedAddress(address);

      if (targetAddress) {
        targetAddress.metropolitanType = sido;
      }
    },
    selectSigunguByAddress(address: string, sigungu: string) {
      const targetAddress = this.getSelectedAddress(address);

      if (targetAddress) {
        targetAddress.sigungu = sigungu;
      }
    },
  }));

  useEffect(() => {
    runInAction(() => {
      mob.branchAddresses = [...(branchAddresses || [])];
      mob.selectedAddresses = [...(selectedAddresses || [])];
      mob.selectableMetropolitanTypes = [...(selectableMetropolitanTypes || [])];
    });
  }, [branchAddresses, selectedAddresses, selectableMetropolitanTypes]);

  return (
    <div className={qst.questionForm}>
      <div className={qst.qusetion}>
        <div className={qst.questionTitle}>주소 변경이 필요한 지점을 선택하세요.</div>
        <div className={st.checkboxList}>
          {branchAddresses.map((address, index) => (
            <CheckBox
              key={address}
              value={address}
              checked={mob.checkSelected(address)}
              onChange={() => mob.selectAddress(address)}
            >
              <b>지점 {index + 1}</b> {address}
            </CheckBox>
          ))}
          {mob.selectedAddresses.length === 0 && (
            <div>
              <input type="hidden" name="agendaDetail.nothing-checked" value={''} />
              <div>
                <Error
                  name="agendaDetail.nothing-checked"
                  value={''}
                  errorCase={{ required: '지점을 선택해주세요.' }}
                />
              </div>
            </div>
          )}
        </div>
      </div>
      {mob.selectedAddresses.length > 0 && (
        <div className={qst.qusetion}>
          <div className={qst.questionTitle}>변경할 주소의 시/군/구를 선택하세요.</div>
          <div>
            {mob.selectedAddresses.map((address, index) => (
              <SelectRow
                key={address.address}
                index={index}
                category={`지점 ${branchAddresses.indexOf(address.address) + 1}`}
                address={address}
                mob={mob}
              />
            ))}
          </div>
        </div>
      )}
    </div>
  );
});

interface SelectRowProps {
  index: number;
  category: string;
  address: I변경된_기존_지점_주소;
  mob: Mob;
}

const SelectRow = observer(function SelectRow(props: SelectRowProps) {
  const { index, category, address, mob } = props;

  const currentAddress = address.address;
  const selectedSidoType = address.metropolitanType || '';
  const selectedSigungu = address.sigungu || '';
  const selectableSigungus =
    mob.selectableMetropolitanTypes.find((metropolitanType) => metropolitanType.metropolitanType === selectedSidoType)
      ?.selectableSigungus || [];

  return (
    <div key={address.address} className={st.addressRow}>
      <div className={st.header}>
        <div className={st.category}>{category}</div>
        <div className={st.address}>{address.address}</div>
      </div>
      <div className={st.content}>
        <input type="hidden" name={`agendaDetail.branchChanges[${index}].address`} value={currentAddress} />
        <Select
          name={`agendaDetail.branchChanges[${index}].afterMetropolitanType`}
          value={selectedSidoType}
          onChange={(e) => mob.selectSidoByAddress(currentAddress, e.target.value)}
          style={{ width: '212px' }}
          errorText={
            <Error
              name={`agendaDetail.branchChanges[${index}].afterMetropolitanType`}
              value={selectedSidoType}
              errorCase={{
                required: '시/도를 선택해주세요.',
              }}
            />
          }
        >
          <option value="">시/도</option>
          {mob.selectableMetropolitanTypes.map((metropolitanType) => (
            <option key={metropolitanType.metropolitanType} value={metropolitanType.metropolitanType}>
              {metropolitanType.metropolitanType}
            </option>
          ))}
        </Select>
        <Select
          name={`agendaDetail.branchChanges[${index}].afterSigunguType`}
          value={selectedSigungu}
          onChange={(e) => mob.selectSigunguByAddress(currentAddress, e.target.value)}
          style={{ width: '212px' }}
        >
          <option value="">시/군/구</option>
          {selectableSigungus.map((selectableSigungu) => (
            <option key={selectableSigungu}>{selectableSigungu}</option>
          ))}
        </Select>
      </div>
    </div>
  );
});

export default UpdateBranchAddress;
