import {
  Agenda,
  IA신규지점_설치,
} from '@src/service/Corp/Agenda/Step/FinalCheck/BeforeFinal/AgendaView/AgendaViewService.interface';
import clsx from 'clsx';
import { observer } from 'mobx-react';

import st from '../Agendas.module.scss';

interface Props {
  agendaIndex: number;
  agenda: Agenda<IA신규지점_설치>;
  moreViewYn: boolean;
}

const C신규지점_설치 = observer(function C신규지점_설치(props: Props) {
  const { agendaIndex, agenda } = props;

  return (
    <div className={clsx(st.agenda, props.moreViewYn ? st.opened : '')}>
      <div className={st.title}>
        <div className={st.number}>{agendaIndex + 1}</div>
        <div className={st.text}>
          <span className={st.important}>신규지점 설치</span>
        </div>
        {agenda.decisionBody.map((decisionBody, index) => (
          <div className={st.badge} key={index}>
            {decisionBody}
          </div>
        ))}
      </div>
      {props.moreViewYn && (
        <div className={st.info}>
          <ul className={st.agendaList}>
            {agenda.info.branches.map((branch, index) => (
              <li key={index}>
                <b>신규지점 {index + 1}:</b> {branch.metropolitanType} {branch.sigungu}
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
});

export default C신규지점_설치;
