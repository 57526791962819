import ScrollArea from '@src/pages/common/ScrollArea';
import SideVm from '@src/pages/Corp/Side/SideVm';
import { ArrowRight, Typography } from '@standby/dike_ui';
import Color from '@standby/dike_ui/design-token/color.module.scss';
import { observer } from 'mobx-react';
import { useEffect } from 'react';

import NavBox from '../components/NavBox';
import HeaderSideLink from './components/HeaderSideLink';
import st from './HeaderSidebar.module.scss';

export interface HeaderSidebarProps {
  companyName: string;
  businessRegistrationActiveYn?: boolean;
  awsCreditActiveYn?: boolean;
  internetAssociateActiveYn?: boolean;
  flexLineActiveYn?: boolean;
}

const HeaderSidebar = observer(
  ({
    companyName,
    businessRegistrationActiveYn,
    awsCreditActiveYn,
    internetAssociateActiveYn,
    flexLineActiveYn,
  }: HeaderSidebarProps) => {
    useEffect(() => {
      SideVm.setCompanyName(companyName);
      SideVm.setBusinessRegistrationActiveYn(!!businessRegistrationActiveYn);
      SideVm.setAwsCreditActiveYn(!!awsCreditActiveYn);
      SideVm.setInternetAssociateActiveYn(!!internetAssociateActiveYn);
      SideVm.setFlexLineActiveYn(!!flexLineActiveYn);
    }, [companyName, businessRegistrationActiveYn, awsCreditActiveYn, internetAssociateActiveYn, flexLineActiveYn]);

    return (
      <>
        <header className={st.header}>
          <img src="/images/logo.svg?1" alt="스탠바이 서비스 로고" title="스탠바이 서비스 로고" />
        </header>
        <div className={st.side}>
          {companyName && (
            <Typography tag="div" fontType="Body-16M" className={st.companyName}>
              {companyName}
            </Typography>
          )}
          <div className={st.joinStandby}>
            <a className={st.joinStandbyLink} href="/auth/joinMembership" target="_blank">
              <Typography fontType="Body-16R" style={{ color: Color.primary400 }}>
                스탠바이 가입하기
              </Typography>
              <ArrowRight width={16} height={16} fill={Color.primary400} />
            </a>
          </div>
          <ScrollArea className={st.scrollArea} thumbWidth={4} thumbColor={'#ADAFB1'}>
            <nav>
              {SideVm.navItems(true).map((item, idx) => (
                <NavBox key={idx} {...item}>
                  {item.links.map((link, idx) => (
                    <HeaderSideLink
                      key={idx}
                      menuName={link.menuName}
                      publicLink={link?.publicLink}
                      isActiveLabel={link.isActiveLabel}
                    />
                  ))}
                </NavBox>
              ))}
            </nav>
          </ScrollArea>
          <div className={st.blogWrap}>
            <div className={st.blogContent}>
              <Typography fontType="Body-14M" className={st.guideTitle}>
                꼭 알아야 할
              </Typography>
              <a
                href="https://standby.kr/blog/category/%EB%B2%95%EC%9D%B8%EC%84%A4%EB%A6%BD%20%EC%A7%81%ED%9B%84%20%ED%95%A0%20%EC%9D%BC/"
                target="_blank"
                rel="noreferrer"
              >
                <Typography fontType="Body-14M" className={st.guideText}>
                  법인 운영 기본 가이드
                </Typography>
              </a>
            </div>
          </div>
        </div>
      </>
    );
  },
);

export default HeaderSidebar;
