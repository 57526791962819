import Loading from '@commonComponents/Loading/Loading';
import NewModalModel from '@model/newModalModel';
import { CompanyBuild } from '@standby/dike_ui/assets/icon/fill';
import { Button, Typography } from '@standby/dike_ui/components/atoms';
import { ModalContentWrapper, ModalLayout, ModalTitle } from '@standby/dike_ui/components/molecules';
import color from '@standby/dike_ui/design-token/color.module.scss';
import { useObserver } from 'mobx-react';

import st from './RegisterPopup.module.scss';
import RegisterPopupVm from './RegisterPopupVm';

function RegisterPopup(props) {
  return useObserver(() => (
    <ModalLayout>
      <ModalTitle title="아래 법인으로 등록을 진행해 드릴게요!" onClickClose={() => NewModalModel.closeModal()} />
      <ModalContentWrapper className={st.modalContent}>
        {RegisterPopupVm.state.loadingYn && <Loading />}
        <div className={st.companyInfo}>
          <Typography fontType="Body-14R" className={st.companyTitle}>
            <CompanyBuild width="16" height="16" fill={color.primary400} />
            {props.companyName}
          </Typography>
          <Typography fontType="Detail-12M" className={st.companyAddress}>
            {props.companyName}
          </Typography>
        </div>
        <div className={st.btnArea}>
          <Button
            color="primary400"
            variant="outlined"
            className={st.squareBtn}
            onClick={() => RegisterPopupVm.registerBtn(props.param)}
          >
            등록 진행하기
          </Button>
        </div>
      </ModalContentWrapper>
    </ModalLayout>
  ));
}

export default RegisterPopup;
