import SealSelectModel from '@model/Corp/Document/SealSelectModel';
import SquareBtn from '@standby/common-ui/components/atoms/Button/SquareBtn/SquareBtn';
import Radio from '@standby/common-ui/components/atoms/Input/Radio/Radio';
import Error from '@standby/common-ui/components/molecules/ErrorValidation/ErrorValidation';
import clsx from 'clsx';
import { observer } from 'mobx-react';

import SealLabelLi from './SealLabelLi';
import st from './style.module.scss';
import type { Props } from './types';
import useSealSelect from './useSealSelect';

const SealSelect = observer((props: Props) => {
  const {
    data,
    includeCorporateSeal,
    changeExecutive,
    changeUseSeal,
    useSeal,
    pickExecutive,
    ExecutiveIdKey,
    type,
    changeEventCallback,
    createSeal,
    isSignature,
  } = useSealSelect(props);

  return (
    <div className={st.sealSelect}>
      <>
        {data.executives.length > 1 && includeCorporateSeal && isSignature === false && (
          <div className={st.selectGroup}>
            <div className={st.title}>서류 아래에 기재할 대표님 이름</div>
            <div className={st.content}>
              <div className={st.radioGroup}>
                {data.executives.map((executive, index) => (
                  <Radio key={index} name="executives" value={executive.id} onChange={(e) => changeExecutive(e)}>
                    {executive.name}님
                  </Radio>
                ))}
                <Radio name="executives" value="ALL" onChange={(e) => changeExecutive(e)}>
                  대표님 모두
                </Radio>
              </div>
            </div>
          </div>
        )}
        {SealSelectModel.selectedSeals.length > 0 && (
          <div className={st.selectGroup}>
            {includeCorporateSeal && isSignature === false && <div className={st.title}>인감날인</div>}
            <div className={st.content}>
              {includeCorporateSeal && isSignature === false && (
                <>
                  <div className={st.radioGroup}>
                    <Radio checked={useSeal === true} name="useSeal" value="true" onChange={(e) => changeUseSeal(e)}>
                      포함
                    </Radio>
                    <Radio checked={useSeal === false} name="useSeal" value="false" onChange={(e) => changeUseSeal(e)}>
                      미포함
                    </Radio>
                  </div>
                </>
              )}
              {useSeal === true && !data.executives.some((executive) => executive?.seal?.id) && (
                <div className={clsx(st.item, (includeCorporateSeal === false || isSignature) && st.marginNone)}>
                  {includeCorporateSeal && <div className={st.itemTitle}>법인인감 등록</div>}
                  <div className={st.advertisementWrap}>
                    <img className={st.advertisement} src="/images/법인인감_애니메이션.gif" />
                    <SquareBtn type="button" name="sendEmail" className={st.advertisementBtn} onClick={createSeal}>
                      법인인감 등록하기
                    </SquareBtn>
                  </div>
                </div>
              )}
              {useSeal === true &&
                SealSelectModel.selectedSeals.map((selectedSeal) => {
                  const _pickExecutive = pickExecutive(selectedSeal[ExecutiveIdKey[type]]);

                  return (
                    <div className={st.item} key={selectedSeal[ExecutiveIdKey[type]]}>
                      <div className={st.itemTitle}>
                        {_pickExecutive?.name}님의 인감{(includeCorporateSeal === false || isSignature) && '선택'}
                      </div>
                      <ul className={st.sealList}>
                        {_pickExecutive?.seal?.id && (
                          <SealLabelLi
                            seal={_pickExecutive?.seal}
                            sealType="법인인감"
                            executiveName={_pickExecutive?.name}
                            executiveId={_pickExecutive?.id}
                            type={type}
                            changeEventCallback={changeEventCallback}
                          />
                        )}
                        {data.authorizedSeals.map(
                          (authorizedSeal) =>
                            _pickExecutive && (
                              <SealLabelLi
                                key={authorizedSeal.id}
                                seal={authorizedSeal}
                                sealType="사용인감"
                                executiveName={_pickExecutive?.name}
                                executiveId={_pickExecutive?.id}
                                type={type}
                                changeEventCallback={changeEventCallback}
                              />
                            ),
                        )}
                      </ul>
                      <div>
                        <Error
                          name={`sealExecutiveId${_pickExecutive?.id}`}
                          data-executiveName={_pickExecutive?.name}
                          value={(selectedSeal.sealId ?? '').toString()}
                          errorCase={{
                            required: '인감을 선택하세요.',
                          }}
                        />
                      </div>
                    </div>
                  );
                })}
            </div>
          </div>
        )}
      </>
    </div>
  );
});

export default SealSelect;
