import josa from '@common/module/josa';
import formDataToJson from '@common/module/submit';
import companyRegistrationModel from '@model/companyRegistrationModel';
import NewModalModel from '@model/newModalModel';
import companyRegistrationService from '@service/companyRegistrationService';
import { Alert } from '@src/model/newDialogModel';
import { UnorderedList } from '@standby/dike_ui/components/atoms';
import { observable, runInAction } from 'mobx';

class CompanynameSearchVm {
  constructor() {
    this.state = observable({
      광역자치단체: '',
      시군구: '',
      companyName: '',
      chooseCompanyIdx: '',
      chooseIrosFailCompany: false,
      loadingYn: false,
    });
    this.searchKeyword = '';
  }
  load() {
    runInAction(() => {
      companyRegistrationModel.irosStatusType = '';
      companyRegistrationModel.searchResults = [];
    });
    companyRegistrationService.metroplitanList();
  }
  get 광역자치단체s() {
    return companyRegistrationModel.광역자치단체s;
  }
  set광역자치단체(e) {
    this.state.광역자치단체 = e.target.value;
  }
  get 시군구List() {
    let list = [];

    this.광역자치단체s.forEach((item, idx) => {
      if (item.name === this.state.광역자치단체) {
        list = item.시군구s;
      }
    });

    return list;
  }
  set시군구(e) {
    this.state.시군구 = e.target.value;
  }
  setCompanyName(e) {
    this.state.companyName = e.target.value;
  }
  async search(e) {
    e.preventDefault();
    if (formDataToJson(e.target).error) {
      return;
    }

    const param = formDataToJson(e.target).data;

    this.searchKeyword = param.companyName;
    try {
      this.state.loadingYn = true;
      await companyRegistrationService.search(param);
      this.state.chooseCompanyIdx = '';
      this.state.chooseIrosFailCompany = false;
      this.state.loadingYn = false;
    } catch (error) {
      this.state.loadingYn = false;
    }
  }
  get searchResults() {
    return companyRegistrationModel.searchResults;
  }
  get irosStatusType() {
    return companyRegistrationModel.irosStatusType;
  }
  get displaySearchResults() {
    if (this.irosStatusType === 'SUCCESS') {
      if (this.searchResults.length) {
        return 'Results';
      }

      return 'None';
    } else if (this.irosStatusType === 'FAIL') {
      return 'IrosError';
    }
  }
  async chooseCompany(e) {
    this.state.chooseCompanyIdx = e.target.value;
  }
  chooseIrosFailCompany(e) {
    this.state.chooseIrosFailCompany = e.target.checked;
  }
  async submitNextBtn(e, companyData) {
    e.preventDefault();
    if (formDataToJson(e.target).error) {
      return;
    }
    if (this.irosStatusType === 'SUCCESS') {
      const index = Number(this.state.chooseCompanyIdx);
      const companyType = this.searchResults[index].companyType;

      if (companyType === '유한회사' || companyType === '유한책임회사') {
        return Alert(
          `앗! ${companyType} 법인등록 기능은 제공하고 있지 않아요.`,
          <UnorderedList>
            <li>법인등록은 현재 주식회사만 가능해요. 양해 부탁드릴게요</li>
          </UnorderedList>,
          '확인',
        );
      }

      const data = {
        office: this.searchResults[index].office,
        registerNum: this.searchResults[index].registerNum,
      };
      const registerYn = await companyRegistrationService.registrationState(data);

      if (registerYn === false) {
        runInAction(() => {
          companyData.companyType = companyType;
          companyData.companyName = this.searchResults[index].companyName;
          companyData.address = this.searchResults[index].address
            ? this.searchResults[index].address
            : this.state.광역자치단체 + ' ' + this.state.시군구;
          companyData.office = this.searchResults[index].office;
          companyData.registerNum = this.searchResults[index].registerNum;
          NewModalModel.closeModal();
        });
      } else {
        return Alert(
          `앗! ${josa(this.searchResults[index].companyName, '은는')} 다른 회사 구성원분이 이미 등록을 했네요!`,
          <UnorderedList>
            <li>
              {josa(this.searchResults[index].companyName, '을를')} 등록한 구성원 분에게 담당자 추가를 요청해주세요.
            </li>
            <li>
              혹시 어떤 구성원이 등록했는지 모르시겠다면, 오른쪽 옆 채팅상담 버튼을 눌러 고객센터로 문의를 남겨주세요.
            </li>
          </UnorderedList>,
          '확인',
        );
      }
    } else if (this.irosStatusType === 'FAIL') {
      runInAction(() => {
        companyData.companyType = '주식회사';
        companyData.companyName = this.state.companyName;
        companyData.address = this.state.광역자치단체 + ' ' + this.state.시군구;
        NewModalModel.closeModal();
      });
    }
  }
}

export default CompanynameSearchVm;
