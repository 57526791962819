import formDataToJson from '@common/module/submit';
import beforeFinalModel from '@model/Corp/Agenda/Step/FinalCheck/BeforeFinal/beforeFinalModel';
import documentModel from '@model/Corp/Agenda/Step/FinalCheck/BeforeFinal/documentModel';
import corpModel from '@model/Corp/corpModel';
import SealSelectModel from '@model/Corp/Document/SealSelectModel';
import portalModel from '@model/portalModel';
import SendStatePopup from '@pages/Corp/Popup/SendStatePopup/SendStatePopup';
import documentService from '@service/Corp/Agenda/Step/FinalCheck/BeforeFinal/documentService';
import type {
  I소집통지서,
  LoadDocumentParam,
} from '@service/Corp/Agenda/Step/FinalCheck/BeforeFinal/documentService.interface';
import { Alert } from '@src/model/newDialogModel';
import { observable, runInAction } from 'mobx';
import React from 'react';

interface State {
  representativeId: string;
  fileShowYn: boolean;
  attachmentFiles: File[];
  options: I소집통지서;
  loadSelectionYn: boolean;
}

class DocumentVm {
  state: State = observable({
    representativeId: 'ALL',
    fileShowYn: true,
    attachmentFiles: [],
    options: {
      fontSize: 'MEDIUM',
      lineSpacing: 'MEDIUM',
      의결권_대리행사_위임장Yn: true,
      주주전원_서면의결서Yn: true,
    },
    loadSelectionYn: false,
  });
  get corpId() {
    return corpModel.id;
  }
  async loadPreview(agendaProgressId: string) {
    const param = {
      documentType: '소집통지서',
      options: { ...this.state.options, seals: SealSelectModel.selectedSeals },
    } as LoadDocumentParam;

    await documentService.loadDocument(agendaProgressId, param);
  }
  async load(agendaProgressId: string) {
    await documentService.defaultInfo(agendaProgressId, '소집통지서');
    this.state.loadSelectionYn = true;
    void this.loadPreview(agendaProgressId);
  }
  async submit(e: React.FormEvent<HTMLFormElement>, agendaProgressId: string) {
    e.preventDefault();

    const form = e.target as HTMLFormElement;
    const formData = formDataToJson(form);

    // sealExecutiveId로 시작하는 에러 요소가 있는 경우 경고 메시지 표시
    if (formData.errorElementsName.some((name) => name.startsWith('sealExecutiveId'))) {
      // data-executiveName 속성을 가진 요소에서 executiveName 값을 가져옴
      const sealExecutiveNames = Array.from(form.querySelectorAll('[data-executiveName][data-error="error"]')).map(
        (element: Element) => element.getAttribute('data-executiveName'),
      );

      void Alert(`${sealExecutiveNames.join(', ')}님의 인감을 선택하세요.`);
    }

    if (formData.error) {
      return;
    }

    const ParamFormData = new FormData(form);

    ParamFormData.delete('attachmentFiles');
    this.state.attachmentFiles?.forEach((file, idx) => {
      ParamFormData.append(`options.attachmentFiles[${idx}]`, file);
    });
    SealSelectModel.selectedSeals.forEach((selectedSeal, idx) => {
      if (selectedSeal?.agendaExecutiveId) {
        ParamFormData.append(`options.seals[${idx}].agendaExecutiveId`, String(selectedSeal.agendaExecutiveId));
      }
      if (selectedSeal?.sealId) {
        ParamFormData.append(`options.seals[${idx}].sealId`, String(selectedSeal.sealId));
      }
    });

    runInAction(() => {
      portalModel.title = '2. 발송할 주주를 선택하세요.';
      portalModel.content = (
        <SendStatePopup
          agendaProgressId={agendaProgressId}
          documentType="소집통지서"
          statusType="소집통지_온라인"
          formData={ParamFormData}
        />
      );
    });
  }
  get previewLink() {
    return documentModel.previewLink;
  }
  setFontSize(e: React.ChangeEvent<HTMLInputElement>, agendaProgressId: string) {
    this.state.options.fontSize = e.target.value;
    void this.loadPreview(agendaProgressId);
  }
  setLineSpacing(e: React.ChangeEvent<HTMLInputElement>, agendaProgressId: string) {
    this.state.options.lineSpacing = e.target.value;
    void this.loadPreview(agendaProgressId);
  }
  set의결권_대리행사_위임장Yn(e: React.ChangeEvent<HTMLInputElement>, agendaProgressId: string) {
    this.state.options.의결권_대리행사_위임장Yn = e.target.value === 'true';
    void this.loadPreview(agendaProgressId);
  }
  set주주전원_서면의결서Yn(e: React.ChangeEvent<HTMLInputElement>, agendaProgressId: string) {
    this.state.options.주주전원_서면의결서Yn = e.target.value === 'true';
    void this.loadPreview(agendaProgressId);
  }

  setRepresentativeId(e: React.ChangeEvent<HTMLInputElement>, agendaProgressId: string) {
    this.state.representativeId = e.target.value;
    void this.loadPreview(agendaProgressId);
  }
  fileUpload(e: React.ChangeEvent<HTMLInputElement>) {
    if (e.target.files) {
      for (let i = 0; i < e.target.files.length; i++) {
        this.state.attachmentFiles.push(e.target.files[i]);
      }
    }
  }
  fileDelete(idx: number) {
    this.state.attachmentFiles.splice(idx, 1);
  }
  get isRegular() {
    const isRegular = beforeFinalModel.isRegular;

    if (isRegular) {
      return '';
    }

    return '임시 ';
  }
}

export default DocumentVm;
