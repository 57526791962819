import { Agenda } from '@src/model/Corp/Agenda/Step/FinalCheck/BeforeFinal/AgendaView/AgendaViewModel.interface';
import { IA기존_지점_주소_변경 } from '@src/service/AgendaDashboard/AgendaDashboardService.interface';
import { observer } from 'mobx-react';

import st from '../Agendas.module.scss';

interface Props {
  agendaIndex: number;
  agenda: Agenda<IA기존_지점_주소_변경>;
}

const C기존_지점_주소_변경 = observer(function C기존_지점_주소_변경(props: Props) {
  const { agendaIndex, agenda } = props;

  return (
    <div className={st.agenda}>
      <div className={st.title}>
        <div className={st.iconTitle}>안건</div>
        <div className={st.number}>{agendaIndex + 1}</div>
        <div className={st.text}>
          <span className={st.important}>지점 주소 변경</span>
        </div>
        {agenda.decisionBody.map((decisionBody, index) => (
          <div className={st.badge} key={index}>
            {decisionBody}
          </div>
        ))}
      </div>
      <div className={st.info}>
        <ul className={st.agendaList}>
          {agenda.info.changedBranches.map((change, index) => (
            <li key={index}>
              <b>지점 {index + 1}:</b> {change.isWithinJurisdiction ? '관내이전' : '관외이전'}
              <div style={{ marginLeft: '45px' }}>
                {`(${change.address} → ${change.metropolitanType}${change.sigunguType ? ` ${change.sigunguType}` : ''})`}
              </div>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
});

export default C기존_지점_주소_변경;
