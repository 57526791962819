import businessRegistrationModel from '@model/Corp/BusinessRegistration/businessRegistrationModel';
import corpModel from '@model/Corp/corpModel';
import businessRegistrationService from '@service/Corp/BusinessRegistration/businessRegistrationService';
import { _alert } from '@src/model/dialogModel';

class AccountInformationVm {
  get statusType() {
    return businessRegistrationModel.businessRegistrationStatuses[
      businessRegistrationModel.businessRegistrationStatuses.length - 1
    ]?.status;
  }
  get isBusinessRegistrationCompleted() {
    return this.statusType === '사업자등록_완료';
  }
  get corpId() {
    return corpModel.id;
  }
  downloadRegistrationFile() {
    const fileLink = businessRegistrationModel.businessRegistrationFile;

    if (!fileLink) {
      void _alert('사업자등록 완료 후 다운로드 가능해요!');

      return;
    }

    void businessRegistrationService.fileDownload(fileLink);
  }
  get corpType() {
    return corpModel.company.corpType;
  }
  get text() {
    const corpType = corpModel.company.corpType;

    switch (corpType) {
      case '주식회사':
        return '주주';
      case '유한회사':
      case '유한책임회사':
        return '사원';
      default:
        return '주주';
    }
  }
  async downloadRosterList() {
    await businessRegistrationService.rosterList();
    const rosterLink = businessRegistrationModel.rosterListDownloadLink;

    await businessRegistrationService.fileDownload(rosterLink);
  }
}

export default new AccountInformationVm();
