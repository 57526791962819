import { createQueryKeys } from '@lukemorales/query-key-factory';
import FlexLineService from '@service/FlexLine/FlexLineService';
import {
  FlexLineSubmitApplicationRq,
  LoadFlexLineRs,
  UserRegistrationTerms,
} from '@service/FlexLine/FlexLineService.interface';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';

const queryKey = createQueryKeys('FlexLine', {
  loadFlexLine: (corpId: string) => [corpId],
  flexLineSubmitApplication: (corpId: string) => [corpId],
  getTermInfo: null,
});

const useLoadFlexLineQuery = (corpId: string) => {
  return useQuery<LoadFlexLineRs>({
    ...queryKey.loadFlexLine(corpId),
    queryFn: () => FlexLineService.loadFlexLine(corpId),
    enabled: !!corpId,
  });
};

const useFlexLinePostMutation = (corpId: string) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (param: FlexLineSubmitApplicationRq) => FlexLineService.flexLineSubmitApplication(corpId, param),
    onSuccess: () => {
      void queryClient.invalidateQueries({ queryKey: queryKey.loadFlexLine(corpId).queryKey });
    },
  });
};

const useFlexLineTermQuery = () => {
  return useQuery<UserRegistrationTerms[]>({
    ...queryKey.getTermInfo,
    queryFn: () => FlexLineService.getTermInfo(),
    staleTime: 1000 * 60 * 10,
  });
};

export { useFlexLinePostMutation, useFlexLineTermQuery, useLoadFlexLineQuery };
