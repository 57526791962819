import Illust from '@src/images/illust_error_page_guide.svg';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import st from './style.module.scss';

type ErrorType = 'ERROR' | 'NOT_FOUND';

interface ErrorPageProps {
  type: ErrorType;
}

const getTitleAndDescription = (type: ErrorType) => {
  switch (type) {
    case 'ERROR':
      return {
        title: '일시적인 오류가 발생했어요.',
        description: '일시적인 시스템 문제로 오류가 발생했어요.',
      };
    case 'NOT_FOUND':
      return {
        title: '요청하신 페이지를 찾을 수 없어요.',
        description: '일시적인 시스템 오류로 인해 화면을 찾을 수 없어요.',
      };
  }
};

const ErrorPage = ({ type = 'ERROR' }: ErrorPageProps) => {
  const navigate = useNavigate();

  const { title, description } = getTitleAndDescription(type);

  const goBack = () => {
    navigate(-1);
  };

  const goHome = () => {
    navigate('/', { replace: true });

    setTimeout(() => {
      window.location.reload();
    }, 100);
  };

  useEffect(() => {
    const handlePopState = () => {
      window.location.reload();
    };

    window.addEventListener('popstate', handlePopState);

    return () => {
      window.removeEventListener('popstate', handlePopState);
    };
  }, []);

  return (
    <div className={st.errorPage}>
      <div className={st.guide}>
        <div className={st.header}>
          <img src="/images/logo_standby.svg?1" alt="STANDBY | 스탠바이 헬프미" />
        </div>
        <div className={st.content}>
          <img src={Illust} alt="" />
          <div className={st.errorContent}>
            <div className={st.title}>{title}</div>
            <div className={st.description}>이용에 불편을 드려 죄송해요.</div>
            <div className={st.description}>
              접속하신 주소가 잘못되었거나,
              <br />
              {description}
            </div>
          </div>
          <div className={st.buttons}>
            <button className={st.buttonBack} onClick={goBack}>
              이전페이지로 이동
            </button>
            <button className={st.buttonHome} onClick={goHome}>
              홈으로 이동
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ErrorPage;
