import portalModel from '@model/portalModel';
import { runInAction } from 'mobx';
import { JSX, useEffect, useState } from 'react';

import { useFlexLineTermQuery } from '../../queries';
import TermsModal from './components/TermsModal';

interface TermInfo {
  id: number;
  title: string;
  content: string;
  required: boolean;
  agreeYn: boolean;
}

export interface TermBundle {
  termInfos: TermInfo[];
  isAllAgreed: boolean;
  isRequiredTermsAgreed: boolean;
  agreeTermByIndex(agree: boolean, selectedIdx: number): void;
  agreeAllTerms(agree: boolean): void;
  openTermDetailPopup(title: string, content: JSX.Element): void;
}

const useTerm = (): TermBundle => {
  const termQueryResult = useFlexLineTermQuery();

  const [termInfos, setTermInfos] = useState<TermInfo[]>([]);

  const agreeTermByIndex = (agree: boolean, selectedIdx: number) =>
    setTermInfos((prev) => prev.map((term, idx) => (selectedIdx === idx ? { ...term, agreeYn: agree } : term)));
  const agreeAllTerms = (agree: boolean) => setTermInfos((prev) => prev.map((term) => ({ ...term, agreeYn: agree })));
  const isAllAgreed = termInfos.length !== 0 && termInfos.every((term) => term.agreeYn);
  const isRequiredTermsAgreed = termInfos.length !== 0 && termInfos.every((term) => !term.required || term.agreeYn);

  // TODO: 약관팝업 전체적으로 디자인해서 디자인시스템 적용해야 함
  const openTermDetailPopup = (title: string, content: JSX.Element) =>
    runInAction(() => {
      portalModel.title = title;
      portalModel.content = <TermsModal content={content} />;
    });

  useEffect(() => {
    if (!termQueryResult.isSuccess) return;

    setTermInfos(
      termQueryResult.data.map((term) => {
        return {
          id: term.id,
          title: term?.title || '',
          content: term?.content || '',
          required: term?.required ?? false,
          agreeYn: false,
        };
      }),
    );
  }, [termQueryResult.data, termQueryResult.isSuccess]);

  return { termInfos, isAllAgreed, isRequiredTermsAgreed, agreeTermByIndex, agreeAllTerms, openTermDetailPopup };
};

export default useTerm;
