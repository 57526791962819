import './App.scss';

import ChannelService from '@common/module/ChannelService';
import ErrorBoundary from '@common/module/ErrorBoundary';
import Loading from '@commonComponents/Loading/Loading';
import { newDialogModel } from '@model/newDialogModel';
import NewModalModel from '@model/newModalModel';
import AgendaDashboard from '@pages/AgendaDashboard/AgendaDashboard';
import Auth from '@pages/Auth/Auth';
import AuthBusinessRegistration from '@pages/AuthBusinessRegistration/AuthBusinessRegistration';
import ChannelTalkOnly from '@pages/ChannelService/ChannelTalkOnly';
import CompanyRegistration from '@pages/CompanyRegistration/CompanyRegistration';
import Corp from '@pages/Corp/Corp';
import Document from '@pages/Document/Document';
import DocumentCertification from '@pages/DocumentCertification/DocumentCertification';
import ErrorPage from '@pages/ErrorPage';
import Guest from '@pages/Guest/Guest';
import Terms from '@pages/Terms/Terms';
import UserDashboard from '@pages/UserDashboard/UserDashboard';
import { PortalProvider } from '@provider/PortalProvider';
import Dialog from '@standby/common-ui/components/molecules/Dialog/Dialog';
import Popup from '@standby/common-ui/components/molecules/Popup/Popup';
import { Dialog as NewDialog } from '@standby/dike_ui/components/molecules';
import { useObserver } from 'mobx-react';
import queryString from 'query-string';
import { useEffect } from 'react';
import ReactGA from 'react-ga4';
import { Navigate, Route, Routes, useLocation } from 'react-router-dom';

import AppVm from './AppVm';

function App() {
  const location = useLocation();
  const openChannel = queryString.parse(location.search).openChannel;

  useEffect(() => {
    window.addEventListener('storage', (e) => {
      if (e.key === 'userId') {
        if (localStorage.getItem('userId') !== AppVm.user.id) {
          AppVm.socketDisConnect();
          window.location.href = `/auth/login`;
        }
      }
    });
  }, []);
  useEffect(() => {
    window.scrollTo({ top: '0' });
    async function fetchData() {
      if (
        location.pathname.indexOf('auth') === -1 &&
        location.pathname.indexOf('authBusinessRegistration') === -1 &&
        location.pathname.indexOf('guest') === -1
      ) {
        if (AppVm.isLogined && !AppVm.user.id) {
          await AppVm.loginedStart();
        }
        if (AppVm.user.id) {
          AppVm.launchChannelService(AppVm.user.channelMemberId, location.pathname, openChannel, AppVm.isDemo);
        } else {
          ChannelService.shutdown();
        }
      }
    }

    fetchData();
    //마케팅 구글에널리틱스 관련 코드
    ReactGA.initialize(process.env.REACT_APP_TRACKING_ID);
    ReactGA.set({ page: location.pathname });
    ReactGA.send('pageview');
  }, [location.pathname]);

  useEffect(() => {
    if (NewModalModel.modalComponent || AppVm.newDialogType) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'auto';
    }

    return () => {
      document.body.style.overflow = 'auto';
    };
  }, [NewModalModel.modalComponent, AppVm.newDialogType]);

  return useObserver(() => (
    <ErrorBoundary fallback={<ErrorPage type="ERROR" />}>
      <div id="app">
        <PortalProvider elementId="popup">{NewModalModel.modalComponent}</PortalProvider>
        <PortalProvider elementId="dialog">
          {newDialogModel.type && (
            <NewDialog
              type={newDialogModel.type}
              withIcon={newDialogModel.withIcon}
              title={newDialogModel.title}
              onClickButton={newDialogModel.onClickButton}
              onClickConfirmButton={newDialogModel.onClickConfirmButton}
              onClickCancelButton={newDialogModel.onClickCancelButton}
              buttonText={newDialogModel.buttonText}
              confirmButtonText={newDialogModel.confirmButtonText}
              cancelButtonText={newDialogModel.cancelButtonText}
            >
              {newDialogModel.contents}
            </NewDialog>
          )}
        </PortalProvider>
        {AppVm.popupContent && (
          <Popup
            title={AppVm.popupTitle || ''}
            content={AppVm.popupContent}
            closeBtn={AppVm.popupCloseBtn}
            radius={AppVm.popupRadius}
            onClose={() => AppVm.popupClose()}
            portalModel={AppVm.portalModel}
            closeBtnDark={AppVm.popupCloseBtnDarkYn}
          />
        )}
        {AppVm.dialogType && (
          <Dialog
            type={AppVm.dialogType}
            title={AppVm.dialogTitle}
            content={AppVm.dialogContent}
            btn={AppVm.dialogBtn}
            alertBtnText={AppVm.dialogAlertBtnText}
            confirmBtnText={AppVm.dialogConfirmBtnText}
            cancelBtnText={AppVm.dialogCancelBtnText}
            contentJSX={AppVm.dialogContentJSX}
            icon={AppVm.dialogIcon}
          />
        )}
        <Routes>
          <Route path="*" element={<Navigate to={AppVm.isLogined ? '/' : '/auth/login'} replace={true} />} />
          {/* 로그인없이 접근가능한 url */}
          {/*메인페이지*/}
          <Route
            path="/"
            element={AppVm.isLogined ? <UserDashboard /> : <Navigate to="/auth/login" replace={true} />}
            exact
          />
          {/*회원 인증관련 페이지 (로그인,회원가입,비번찾기등)*/}
          <Route path="/auth/*" element={<Auth />} />
          {/* 서류 확인 인증 페이지 */}
          <Route path="/documentCertification" element={<DocumentCertification />} />
          {/* 서류 확인 페이지 */}
          <Route path="/document" element={<Document />} />
          {/* 사업자 등록 회원가입/로그인 페이지 */}
          <Route path="/authBusinessRegistration/*" element={<AuthBusinessRegistration />} />
          {/* 게스트 페이지 */}
          <Route path="/guest/*" element={<Guest />} />
          {/* 약관 페이지 */}
          <Route path="/terms" element={<Terms />} />
          {/* 안건대시보드 */}
          <Route path="/agendaDashboard" element={<AgendaDashboard />} />

          {/* 로그인후 접근가능한 url */}
          {/*법인페이지*/}
          <Route path="/corp/*" element={<Corp />} />
          {/*계정대시보드*/}
          <Route path="/userDashboard" element={<UserDashboard />} />
          {/* 법인등록 */}
          <Route path="/companyRegistration" element={<CompanyRegistration />} />
          {/* 모바일채널톡 진평 */}
          <Route path="/accounting/channelTalkOnly" element={<ChannelTalkOnly isLogined={AppVm.isLogined} />} />
          {/* 모바일채널톡 */}
          <Route path="/channelTalkOnly" element={<ChannelTalkOnly isLogined={AppVm.isLogined} />} />
        </Routes>
        {AppVm.loadingShowYn && <Loading />}
      </div>
    </ErrorBoundary>
  ));
}

export default App;
