import { addCommas } from '@common/module/replaceNumber';
import PageMainTitle from '@commonComponents/PageMainTitle';
import { Customer, Product } from '@service/FlexLine/FlexLineService.interface';
import { Star } from '@standby/dike_ui/assets/icon/fill';
import { ArrowRight } from '@standby/dike_ui/assets/icon/line';
import { IconButton, Typography } from '@standby/dike_ui/components/atoms';
import color from '@standby/dike_ui/design-token/color.module.scss';
import { observer } from 'mobx-react';

import Faq from './components/Faq';
import FormInput from './components/FormInput';
import st from './style.module.scss';
import useBeforeApplicationSubmission from './useBeforeApplicationSubmission';
import useTerm from './useTerm';

const BeforeApplicationSubmission = observer(
  ({ customer, products, corpId }: { customer: Customer; products: Product[]; corpId: string }) => {
    const termBundle = useTerm();
    const beforeApplicationSubmissionBundle = useBeforeApplicationSubmission(
      customer,
      termBundle.isRequiredTermsAgreed,
      corpId,
    );

    return (
      <form
        className={st.beforeApplicationSubmission}
        onSubmit={(e) => beforeApplicationSubmissionBundle.onSubmitForm(e)}
      >
        <PageMainTitle
          icon={<Star width="24" height="24" fill={color.primary800} />}
          title="KT 랜섬웨어 방화벽"
          rightContent={
            <IconButton size="small" type="submit" rightIcon="ArrowRight" color="primary400">
              KT 랜섬웨어 방화벽 상담 신청하기
            </IconButton>
          }
        />
        <img
          src="/images/flexLine_Introduction.png"
          className={st.flexLineIntroduction}
          alt="KT 랜섬웨어 방화벽 소개"
        />
        <Faq />
        <FormInput
          products={products}
          termBundle={termBundle}
          beforeApplicationSubmissionBundle={beforeApplicationSubmissionBundle}
        />
        <div className={st.btnArea}>
          <button type="submit">
            <Typography fontType="Title-24B" className={st.btnText}>
              KT 랜섬웨어 방화벽 상담 신청하기
              <ArrowRight fill={color.grayScale000} height={20} width={20} />
            </Typography>
            <ul>
              <li>
                <Typography fontType="Body-16R">
                  대역폭 옵션 :{' '}
                  {products.find((product) => product.id === beforeApplicationSubmissionBundle.formMob.product)?.name}
                </Typography>
              </li>
              <li>
                <Typography fontType="Body-16R">
                  월 요금 :{' '}
                  {addCommas(
                    products.find((product) => product.id === beforeApplicationSubmissionBundle.formMob.product)
                      ?.price ?? 0,
                  )}
                  원
                </Typography>
              </li>
              <li>
                <Typography fontType="Body-16R">
                  캐시백 :{' '}
                  {addCommas(
                    products.find((product) => product.id === beforeApplicationSubmissionBundle.formMob.product)
                      ?.cashback ?? 0,
                  )}
                  원
                </Typography>
              </li>
            </ul>
          </button>
        </div>
      </form>
    );
  },
);

export default BeforeApplicationSubmission;
