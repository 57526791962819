import { addCommas } from '@common/module/replaceNumber';
import ContentBox from '@commonComponents/ContentBox/ContentBox';
import TooltipIcon from '@src/images/tooltip.svg';
import FileBtn from '@standby/common-ui/components/atoms/Button/FileBtn/FileBtn';
import clsx from 'clsx';
import { useObserver } from 'mobx-react';
import { useEffect } from 'react';

import st from './StepPage.module.scss';
import vm from './StepPageVm';

function CssState() {
  if (vm.nowStep === '국세_신고완료' || vm.nowStep === '지방세_신고완료') {
    return st.separateTax;
  }

  return st.basic;
}

function CssStep(
  step: '안내문_발송전' | '자료제출_대기중' | '결산_진행중' | '국세_신고완료' | '지방세_신고완료' | '법인세_신고완료',
) {
  switch (step) {
    case '결산_진행중':
      return st.step3;
    case '국세_신고완료':
      return st.step4;
    case '지방세_신고완료':
      return st.step5;
    case '법인세_신고완료':
      return st.step6;
    default:
      return undefined;
  }
}

function StepPage() {
  useEffect(() => {
    void vm.load();
  }, []);

  return useObserver(() => (
    <>
      {vm.isBookkeepingServiceEnded && (
        <div className={st.info}>
          <div className={st.icon}>
            <img src={TooltipIcon} />
          </div>
          <div className={st.title}>{`${vm.bookkeepingServiceEndAt} 기장계약 해지`}</div>
          <div className={st.content}>
            기장서비스 이용 시 받아보셨던 법인세 자료입니다. 재이용을 원하시다면, 우측 하단 버튼을 눌러 문의해 주세요.
          </div>
        </div>
      )}
      {!vm.isBookkeepingServiceEnded && (
        <ContentBox
          title="법인세 신고 현황"
          titleStyle={{ top: '15px', paddingLeft: '18px', fontSize: '18px' }}
          className={st.contentBox}
          titleComponent={
            <select className={st.select} value={vm.state.corporateTaxesId} onChange={(e) => vm.setCorporateTaxes(e)}>
              {vm?.selectableCorporateTaxes.map((item, idx) => (
                <option key={idx} value={item.id}>
                  {item.title}
                </option>
              ))}
            </select>
          }
        >
          <div className={st.content}>
            <div className={st.settlementPeriod}>
              결산기간 :
              <span className={st.date}>
                {vm.settlementPeriod.startAt.year +
                  '년 ' +
                  vm.settlementPeriod.startAt.month +
                  '월 ' +
                  vm.settlementPeriod.startAt.day +
                  '일 ~ '}
                {vm.settlementPeriod.endAt.year +
                  '년 ' +
                  vm.settlementPeriod.endAt.month +
                  '월 ' +
                  vm.settlementPeriod.endAt.day +
                  '일'}
              </span>
            </div>
            <div className={st.taxFiling}>
              신고기간 :
              <span className={st.date}>
                {vm.taxFilingEndAt.year +
                  '년 ' +
                  vm.taxFilingEndAt.month +
                  '월 ' +
                  vm.taxFilingEndAt.day +
                  '일까지 신고 및 납부'}
                {vm.faithfulnessTaxPayerYn && '(성실신고 대상)'}
              </span>
            </div>
            <div className={st.stepInfoBox}>
              <img src={`/images/${vm.nowStep}.svg`} className={st.stepImg} />
              {vm?.steps.map((step, idx) => {
                if (step.step !== '안내문_발송전') {
                  return (
                    <div key={idx} className={clsx(st.stepInfo, CssState())}>
                      {step.step === '자료제출_대기중' ? (
                        <>
                          <div className={clsx(st.step2, st.collection)}>{step.startAt} 수집시작</div>
                          <div className={clsx(st.step2, st.waiting)}>{step.startAt} 제출요청</div>
                        </>
                      ) : (
                        <div className={CssStep(step.step)}>
                          <span>{step.startAt}</span>
                          <span className={st.text}>
                            {step.step === '결산_진행중' && '결산시작'}
                            {(step.step === '법인세_신고완료' ||
                              step.step === '국세_신고완료' ||
                              step.step === '지방세_신고완료') &&
                              '신고완료'}
                          </span>
                        </div>
                      )}
                    </div>
                  );
                }
              })}
            </div>
          </div>
        </ContentBox>
      )}
      {vm.displayMaterial() && !vm.isBookkeepingServiceEnded && (
        <ContentBox
          title={vm.materialTitle()}
          titleStyle={{ top: '15px', paddingLeft: '18px', fontSize: '18px' }}
          className={st.contentBox}
        >
          <div className={st.content}>
            <div className={st.material}>
              <div className={st.title}>1. 법인세 신고를 위해 아래 자료를 준비하세요!</div>
              {vm.acctFirmCode === 'JINPYUNG' && (
                <a
                  className={st.materialLink}
                  href={`${process.env.REACT_APP_S3URL}/accountingFirm/jinpyung/ct/%EB%B2%95%EC%9D%B8%EC%84%B8_%EC%A4%80%EB%B9%84%EC%9E%90%EB%A3%8C_%EC%95%88%EB%82%B4%EB%AC%B8_PC.pdf`}
                  download
                >
                  <div className={st.btnTitle}>법인세 신고 준비자료</div>
                  <div className={st.companyName}>{vm.companyName}</div>
                </a>
              )}
              {vm.acctFirmCode === 'BARUN' && (
                <a
                  className={st.materialLink}
                  href={`${process.env.REACT_APP_S3URL}/accountingFirm/barun/ct/%EB%B2%95%EC%9D%B8%EC%84%B8+%EC%8B%A0%EA%B3%A0%EC%9E%90%EB%A3%8C+%EC%95%88%EB%82%B4.pdf`}
                  download
                >
                  <div className={st.btnTitle}>법인세 신고 준비자료</div>
                  <div className={st.companyName}>{vm.companyName}</div>
                </a>
              )}
            </div>
            <div className={st.material}>
              <div className={st.title}>2. 준비된 자료를 보내주세요!</div>
              <div className={st.deadline}>
                제출 기한 :{' '}
                <span className={st.date}>
                  {vm.deadline.year + '년 ' + vm.deadline.month + '월 ' + vm.deadline.day + '일'}
                </span>
              </div>
              <div className={st.deadlineExplain}>제출기한 연장이 필요한 경우, 카톡으로 알려주세요. (2일 연장가능)</div>
              <div className={st.reception}>
                제출처 : <span className={st.email}>{vm.emailToSubmit}</span>로 제출
              </div>
            </div>
            <div className={st.corporateTaxQuestion}>
              법인세 신고, 궁금한 점이 있으신가요?
              <a
                href="https://standby.kr/blog/article/%EB%B2%95%EC%9D%B8%EC%84%B8%EC%9D%98%EB%AA%A8%EB%93%A0%EA%B2%83/"
                target="_blank"
                rel="noreferrer"
              >
                법인세의 모든 것 A-Z
              </a>
            </div>
          </div>
        </ContentBox>
      )}
      {vm.corporateTaxes && vm.corporateTaxes.length !== 0 && (
        <ContentBox
          title="법인세 신고 및 납부자료"
          titleStyle={{ top: '15px', paddingLeft: '18px', fontSize: '18px' }}
          className={st.contentBox}
        >
          <div className={st.content}>
            {vm.corporateTaxes.map((item, idx) => {
              return (
                <div className={st.corporateTaxesBox} key={idx}>
                  <div className={st.corporateTaxesTitle}>{item.title} 법인세</div>
                  <div className={st.corporateTaxes}>
                    {item.instalmentYn ? ( //분납할 경우
                      <>
                        <div className={clsx(st.instalmentBox, st.boxMargin)}>
                          <div className={st.instalmentBoxTitle}>법인세 계산</div>
                          <div className={st.instalmentInfo}>
                            <div className={st.instalmentTax}>
                              <div className={st.taxName}>법인세(국세)</div>
                              <div className={st.price}>{addCommas(item.nationalTax?.finalTaxLiability)}원</div>
                            </div>
                            {item.nationalTax?.prePaidTax > 0 && (
                              <div className={st.instalmentTax}>
                                <div className={st.taxName}>기납부세액</div>
                                <div className={st.price}>-{addCommas(item.nationalTax?.prePaidTax)}원</div>
                              </div>
                            )}
                            {item.nationalTax?.additionalTaxPayable > 0 && (
                              <div className={st.instalmentTax}>
                                <div className={st.taxName}>추가 납부세액</div>
                                <div className={st.price}>+{addCommas(item.nationalTax?.additionalTaxPayable)}원</div>
                              </div>
                            )}
                            {Number(item.nationalTax?.specialTaxForRuralDevelopment) > 0 && (
                              <div className={st.instalmentTax}>
                                <div className={st.taxName}>농어촌특별세액</div>
                                <div className={st.price}>
                                  +{addCommas(item.nationalTax?.specialTaxForRuralDevelopment || 0)}원
                                </div>
                              </div>
                            )}
                          </div>
                          <div className={st.totalInstalmentTaxInfo}>
                            <div className={st.totalInstalmentTax}>
                              <div className={st.taxName}>총 납부세액</div>
                              <div className={st.price}>{addCommas(item.nationalTax?.taxPayable)}원</div>
                            </div>
                            <div className={st.instalmentExplain}>
                              분납신청 해드렸어요! ({item.nationalTax?.instalmentNationalTaxes?.length}회 분납)
                            </div>
                          </div>
                        </div>
                        {item.nationalTax?.instalmentNationalTaxes?.map((tax, idx2) => {
                          return (
                            <div className={clsx(st.nationalTaxBox, st.boxMargin)} key={idx2}>
                              <div className={st.nationalTaxPriceInfo}>
                                <div className={st.nationalTax}>
                                  <div className={st.taxName}>법인세(국세) {tax.round}차 납부</div>
                                </div>
                              </div>
                              <div className={st.totalNationalTaxInfo}>
                                <div className={clsx(st.totalNationalTax, idx === 0 ? st.highlight : null)}>
                                  <div className={st.taxName}>납부세액</div>
                                  <div className={st.price}>{addCommas(tax.taxPayable)}원</div>
                                </div>
                                <div className={st.dateExplain}>
                                  {tax.dueDateForPayment?.year}년 {tax.dueDateForPayment?.month}월{' '}
                                  {tax.dueDateForPayment?.day}일까지 납부
                                </div>
                              </div>
                              <FileBtn
                                btnType="important"
                                className={st.fileBtn}
                                onClick={() => vm.fileDownload(tax.billFile || '')}
                              >
                                법인세(국세) 납부서
                              </FileBtn>
                            </div>
                          );
                        })}
                      </>
                    ) : (
                      <div className={clsx(st.nationalTaxBox, st.boxMargin)}>
                        <div className={st.nationalTaxPriceInfo}>
                          <div className={st.nationalTax}>
                            <div className={st.taxName}>법인세(국세)</div>
                            <div className={st.price}>{addCommas(item.nationalTax?.finalTaxLiability)}원</div>
                          </div>
                          {item.nationalTax?.prePaidTax > 0 && (
                            <div className={st.nationalTax}>
                              <div className={st.taxName}>기납부세액</div>
                              <div className={st.price}>-{addCommas(item.nationalTax?.prePaidTax)}원</div>
                            </div>
                          )}
                          {item.nationalTax?.additionalTaxPayable > 0 && (
                            <div className={st.nationalTax}>
                              <div className={st.taxName}>추가 납부세액</div>
                              <div className={st.price}>+{addCommas(item.nationalTax?.additionalTaxPayable)}원</div>
                            </div>
                          )}
                          {Number(item.nationalTax?.specialTaxForRuralDevelopment) > 0 && (
                            <div className={st.nationalTax}>
                              <div className={st.taxName}>농어촌특별세액</div>
                              <div className={st.price}>
                                +{addCommas(item.nationalTax?.specialTaxForRuralDevelopment || 0)}원
                              </div>
                            </div>
                          )}
                        </div>
                        <div className={st.totalNationalTaxInfo}>
                          <div
                            className={clsx(
                              st.totalNationalTax,
                              idx === 0 && !item.nationalTax?.taxRefundAmount ? st.highlight : null,
                            )}
                          >
                            <div className={st.taxName}>납부세액</div>
                            <div className={st.price}>{addCommas(item.nationalTax?.taxPayable)}원</div>
                          </div>
                          {item.nationalTax?.taxRefundAmount !== 0 && (
                            <div className={clsx(st.totalNationalTax, idx === 0 ? st.highlight : null)}>
                              <div className={st.taxName}>환급세액</div>
                              <div className={st.price}>{addCommas(item.nationalTax?.taxRefundAmount || '')}원</div>
                            </div>
                          )}
                          {item.nationalTax?.taxPayable === 0 && !item.nationalTax?.taxRefundAmount && (
                            <div className={st.dateExplain}>이번에는 납부세액이 없어요!</div>
                          )}
                          {item.nationalTax?.taxPayable !== 0 && item.nationalTax?.dueDateForPayment?.year && (
                            <div className={st.dateExplain}>
                              {item.nationalTax?.dueDateForPayment?.year}년 {item.nationalTax?.dueDateForPayment?.month}
                              월 {item.nationalTax?.dueDateForPayment?.day}일까지 납부
                            </div>
                          )}
                        </div>
                        {item.nationalTax?.billFile &&
                          (item.nationalTax?.taxPayable !== 0 || item.nationalTax?.taxRefundAmount !== 0) && (
                            <FileBtn
                              btnType="important"
                              className={st.fileBtn}
                              onClick={() => vm.fileDownload(item.nationalTax?.billFile || '')}
                            >
                              법인세(국세) 납부서
                            </FileBtn>
                          )}
                      </div>
                    )}
                    {vm.nowStep === '국세_신고완료' ? (
                      <div className={clsx(st.localTaxBox, st.boxMargin)}>
                        <div className={st.localTaxWaitingInfo}>
                          <div className={st.title}>
                            법인세(지방세) 신고 진행중
                            <br />
                            (국세의 10% 예상)
                          </div>
                        </div>
                        <div className={st.localTaxWaitingDateExplain}>
                          {item.localTax?.dueDateForPayment.year}년 {item.localTax?.dueDateForPayment.month}월{' '}
                          {item.localTax?.dueDateForPayment.day}일까지 납부
                        </div>
                        <FileBtn btnType="important" className={st.fileBtn} disabled>
                          법인세(지방세) 납부서
                        </FileBtn>
                      </div>
                    ) : (
                      <div className={clsx(st.localTaxBox, st.boxMargin)}>
                        <div className={st.localTaxBoxPriceInfo}>
                          <div className={st.localTax}>
                            <div className={st.taxName}>법인세(지방세)</div>
                            {item.instalmentYn === false && (
                              <div className={st.price}>{addCommas(item.localTax?.taxAmount)}원</div>
                            )}
                          </div>
                        </div>
                        <div className={st.totalLocalTaxInfo}>
                          <div
                            className={clsx(
                              st.totalLocalTax,
                              idx === 0 && item.localTax?.taxAmount !== 0 ? st.highlight : null,
                            )}
                          >
                            <div className={st.taxName}>납부세액</div>
                            <div className={st.price}>{addCommas(item.localTax?.taxAmount)}원</div>
                          </div>
                          {item.localTax?.taxRefundAmount !== 0 && (
                            <div className={clsx(st.totalLocalTax, idx === 0 ? st.highlight : null)}>
                              <div className={st.taxName}>환급세액</div>
                              <div className={st.price}>{addCommas(item.localTax?.taxRefundAmount)}원</div>
                            </div>
                          )}
                          {item.localTax?.taxAmount !== 0 && item.localTax?.dueDateForPayment.year && (
                            <div className={st.dateExplain}>
                              {item.localTax?.dueDateForPayment.year}년 {item.localTax?.dueDateForPayment.month}월{' '}
                              {item.localTax?.dueDateForPayment.day}일까지 납부
                            </div>
                          )}
                          {item.localTax?.taxAmount === 0 && !item.localTax?.taxRefundAmount && (
                            <div className={st.dateExplain}>이번에는 납부세액이 없어요!</div>
                          )}
                        </div>
                        {item.localTax?.billFile &&
                          (item.localTax?.taxAmount !== 0 || item.localTax?.taxRefundAmount !== 0) && (
                            <FileBtn
                              btnType="important"
                              className={st.fileBtn}
                              onClick={() => vm.fileDownload(item.localTax?.billFile || '')}
                            >
                              법인세(지방세) 납부서
                            </FileBtn>
                          )}
                      </div>
                    )}
                    {item?.statementOfTaxAdjustmentFile && ( //세무조정계산서
                      <div className={clsx(st.statementOfTaxAdjustmentFile, st.boxMargin)}>
                        <div
                          className={st.thumbnailFile}
                          style={{ backgroundImage: `url(/images/statementOfTaxAdjustmentFile.svg)` }}
                        ></div>
                        <FileBtn
                          btnType="important"
                          className={st.fileBtn}
                          onClick={() => vm.fileDownload(item.statementOfTaxAdjustmentFile || '')}
                        >
                          세무조정계산서
                        </FileBtn>
                      </div>
                    )}
                    {item?.financialStatementsConfirmationFile && ( //재무제표등 확인원
                      <div className={clsx(st.financialStatementsConfirmationFile, st.boxMargin)}>
                        <div
                          className={st.thumbnailFile}
                          style={{ backgroundImage: `url(${item.financialStatementsConfirmationThumbnailFile})` }}
                        ></div>
                        <FileBtn
                          btnType="important"
                          className={st.fileBtn}
                          onClick={() => vm.fileDownload(item.financialStatementsConfirmationFile || '')}
                        >
                          재무제표등 확인원
                        </FileBtn>
                      </div>
                    )}
                    {item?.etcFile && ( //기타서류
                      <div className={clsx(st.etcFile, st.boxMargin)}>
                        <div
                          className={st.thumbnailFile}
                          style={{ backgroundImage: `url(/images/etcThumbnailFile.svg)` }}
                        ></div>
                        <FileBtn
                          btnType="important"
                          className={st.fileBtn}
                          onClick={() => vm.fileDownload(item.etcFile || '')}
                        >
                          법인세 관련 기타서류
                        </FileBtn>
                      </div>
                    )}
                  </div>
                </div>
              );
            })}
          </div>
        </ContentBox>
      )}
    </>
  ));
}

export default StepPage;
