import { ArrowDown, ArrowUp } from '@standby/dike_ui/assets/icon/line';
import { Typography } from '@standby/dike_ui/components/atoms';
import color from '@standby/dike_ui/design-token/color.module.scss';
import { observer } from 'mobx-react';
import { useLocalObservable } from 'mobx-react';

import st from './style.module.scss';

interface FaqMob {
  opened: boolean[];
  toggleTab: (index: number) => void;
}

const faqList = [
  {
    title: '진행은 어떻게 되나요?',
    content:
      '1. 상품 옵션 선택 후, [KT 랜섬웨어 방화벽 상담 신청하기] 버튼을 눌러주세요.<br/>2. 상담이 접수되면, 1 영업일 내 담당자가 전화를 드릴 예정이에요.<br/>3. 전화상담 후 개통이 완료되면 캐시백 수령이 가능해요.',
  },
  {
    title: '상담받고 상품을 변경할 수 있나요?',
    content: '네, 가능해요.<br/>담당자와 전화상담 시 충분히 설명을 듣고, 원하시는 상품으로 선택하세요.',
  },
  {
    title: '저희는 보안 전문팀이 없는데 괜찮을까요?',
    content:
      '네, KT에서 원격 관리 및 기술을 지원합니다.<br/>도입부터 운영까지 담당자가 모두 관리하기 어려움을 알기에, KT 운용센터로부터 지원을 받아 네트워크 운영관리 부담을 최소화하세요.',
  },
];

const Faq = observer(() => {
  const faqMob = useLocalObservable<FaqMob>(() => ({
    opened: [false, false, false],
    toggleTab(index: number) {
      this.opened[index] = !this.opened[index];
    },
  }));

  return (
    <div className={st.faq}>
      <Typography fontType="Title-24B" className={st.title}>
        자주 묻는 질문
      </Typography>
      <ul>
        {faqList.map((faq, index) => (
          <li key={index} onClick={() => faqMob.toggleTab(index)}>
            <Typography fontType="Title-18B" className={st.q}>
              Q.
            </Typography>
            {faqMob.opened[index] ? (
              <ArrowUp fill={color.grayScale700} width="16" height="16" className={st.arrow} />
            ) : (
              <ArrowDown fill={color.grayScale700} width="16" height="16" className={st.arrow} />
            )}
            <Typography fontType="Title-18M" className={st.title}>
              {faq.title}
            </Typography>
            {faqMob.opened[index] && (
              <Typography
                fontType="Body-16R"
                className={st.content}
                dangerouslySetInnerHTML={{ __html: faq.content }}
              />
            )}
          </li>
        ))}
      </ul>
    </div>
  );
});

export default Faq;
