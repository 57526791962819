import type {
  CommonAgendaDetail,
  I대표이사_주소_변경,
  I등기가_불필요한_정관변경,
  I등기신청을_위한_추가_정관변경,
  I본점의_주소_변경,
  I신주발행_무상증자,
  I신주발행_유상증자,
  I액면금분할_합병,
  I임원_보수_결정,
  I임원_보수_한도_승인,
  I임원_취임_중임_사임_퇴임,
  I재무재표_승인,
  I전환사채_발행,
  SelectedAgenda,
} from '@model/Corp/Agenda/Step/agendaStepModel.interface';
import type { I기존_지점_주소_변경, I신규지점_설치 } from '@src/service/Corp/Agenda/agendaService.interface';
import { observer } from 'mobx-react';

import AdditionalAoAChange from './AdditionalAoAChange/AdditionalAoAChange';
import ApprovalofExecutiveRemunerationLimit from './ApprovalofExecutiveRemunerationLimit/ApprovalofExecutiveRemunerationLimit';
import ApprovaOfFinancialStatements from './ApprovaOfFinancialStatements/ApprovaOfFinancialStatements';
import BonusIssue from './BonusIssue/BonusIssue';
import CommonAgenda from './CommonAgenda/CommonAgenda';
import EstablishNewBranch from './EstablishNewBranch';
import ExecutiveRemunerationDecision from './ExecutiveRemunerationDecision/ExecutiveRemunerationDecision';
import Executives from './Executives/Executives';
import HeadOfficeAddressChange from './HeadOfficeAddressChange/HeadOfficeAddressChange';
import IssuanceOfConvertibleBonds from './IssuanceOfConvertibleBonds/IssuanceOfConvertibleBonds';
import ParValueDivisionMerger from './ParValueDivisionMerger/ParValueDivisionMerger';
import RegistratioUnnecessaryChange from './RegistratioUnnecessaryChangeAOA/RegistratioUnnecessaryChangeAOA';
import RepresentativeAddressChange from './RepresentativeAddressChange/RepresentativeAddressChange';
import RightsIssue from './RightsIssue/RightsIssue';
import UpdateBranchAddress from './UpdateBranchAddress';

interface AgendaInputProps {
  agendaIndex: number;
  agenda: SelectedAgenda;
  openFirst: () => void;
}

const AgendaInput = observer(function AgendaInput(props: AgendaInputProps) {
  const agendaType = props.agenda.agendaType;

  return (
    <>
      <input type="hidden" name="agendaDetail.agendaType" value={agendaType} />
      <input type="hidden" name="id" value={props.agenda.id || undefined} />
      {(() => {
        switch (agendaType) {
          case '본점의_주소_변경':
            return (
              <HeadOfficeAddressChange
                agenda={props.agenda as SelectedAgenda<I본점의_주소_변경>}
                agendaIndex={props.agendaIndex}
              />
            );
          case '재무제표_승인':
            return (
              <ApprovaOfFinancialStatements
                agenda={props.agenda as SelectedAgenda<I재무재표_승인>}
                agendaIndex={props.agendaIndex}
              />
            );
          case '대표이사_주소_변경':
            return (
              <RepresentativeAddressChange
                agenda={props.agenda as SelectedAgenda<I대표이사_주소_변경>}
                agendaIndex={props.agendaIndex}
              />
            );
          case '신주발행_무상증자':
            return (
              <BonusIssue agenda={props.agenda as SelectedAgenda<I신주발행_무상증자>} agendaIndex={props.agendaIndex} />
            );
          case '전환사채_발행':
            return (
              <IssuanceOfConvertibleBonds
                agenda={props.agenda as SelectedAgenda<I전환사채_발행>}
                agendaIndex={props.agendaIndex}
              />
            );
          case '등기가_불필요한_정관변경':
            return (
              <RegistratioUnnecessaryChange
                agenda={props.agenda as SelectedAgenda<I등기가_불필요한_정관변경>}
                agendaIndex={props.agendaIndex}
              />
            );
          case '임원_보수_한도_승인':
            return (
              <ApprovalofExecutiveRemunerationLimit
                agenda={props.agenda as SelectedAgenda<I임원_보수_한도_승인>}
                agendaIndex={props.agendaIndex}
              />
            );
          case '임원_보수_결정':
            return (
              <ExecutiveRemunerationDecision
                agenda={props.agenda as SelectedAgenda<I임원_보수_결정>}
                agendaIndex={props.agendaIndex}
              />
            );
          case '임원_취임_중임_사임_퇴임':
            return (
              <Executives
                agenda={props.agenda as SelectedAgenda<I임원_취임_중임_사임_퇴임>}
                agendaIndex={props.agendaIndex}
                openFirst={props.openFirst}
              />
            );
          case '신주발행_유상증자':
            return (
              <RightsIssue
                agenda={props.agenda as SelectedAgenda<I신주발행_유상증자>}
                agendaIndex={props.agendaIndex}
              />
            );
          case '등기신청을_위한_추가_정관변경':
            return (
              <AdditionalAoAChange
                agenda={props.agenda as SelectedAgenda<I등기신청을_위한_추가_정관변경>}
                agendaIndex={props.agendaIndex}
              />
            );
          case '액면금분할_합병':
            return (
              <ParValueDivisionMerger
                agenda={props.agenda as SelectedAgenda<I액면금분할_합병>}
                agendaIndex={props.agendaIndex}
              />
            );
          case '신규지점_설치':
            return (
              <EstablishNewBranch
                agenda={props.agenda as SelectedAgenda<I신규지점_설치>}
                agendaIndex={props.agendaIndex}
              />
            );
          case '기존_지점_주소_변경':
            return (
              <UpdateBranchAddress
                agenda={props.agenda as SelectedAgenda<I기존_지점_주소_변경>}
                agendaIndex={props.agendaIndex}
              />
            );
          case '임원_보수_지급규정_승인':
          case '임원_상여금_지급규정_승인':
          case '임원_퇴직금_지급규정_승인':
          case '주주총회_안건_자유입력':
          case '이사회_안건_자유입력':
          case '차등배당':
          case '중간배당':
          case '부동산_매각':
          case '자금차입':
          case '자금차입_및_담보제공':
          case '자회사_설립':
          case '이사의_자기거래_승인':
          case '임원_무보수_결의':
          case '이익잉여금처분계산서':
            return (
              <CommonAgenda
                agenda={props.agenda as SelectedAgenda<CommonAgendaDetail>}
                agendaIndex={props.agendaIndex}
              />
            );
          default:
            return null;
        }
      })()}
    </>
  );
});

export default AgendaInput;
