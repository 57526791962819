import type {
  Agenda,
  IACommonAgenda,
  IA대표이사_주소_변경,
  IA본점의_주소_변경,
  IA신규지점_설치,
  IA신주발행_무상증자,
  IA신주발행_유상증자,
  IA액면금분할_합병,
  IA임원_보수_결정,
  IA임원_보수_한도_승인,
  IA임원_취임_중임_사임_퇴임,
  IA재무제표_승인,
  IA전환사채_발행,
  IA정관변경,
  IA폼_없음,
} from '@model/AgendaDashboard/AgendaDashboardModel.interface';
import type { IA기존_지점_주소_변경 } from '@src/service/AgendaDashboard/AgendaDashboardService.interface';
import clsx from 'clsx';
import { useObserver } from 'mobx-react';
import { Fragment } from 'react';

import st from '../AgendaDashboard.module.scss';
import Cst from './Agendas.module.scss';
import vm from './AgendasVm';
import CommonAgenda from './CommonAgenda/CommonAgenda';
import C기존_지점_주소_변경 from './C기존_지점_주소_변경';
import C대표이사_주소_변경 from './C대표이사_주소_변경/C대표이사_주소_변경';
import C본점의_주소_변경 from './C본점의_주소_변경/C본점의_주소_변경';
import C신규지점_설치 from './C신규지점_설치';
import C신주발행_무상증자 from './C신주발행_무상증자/C신주발행_무상증자';
import C신주발행_유상증자 from './C신주발행_유상증자/C신주발행_유상증자';
import C액면금분할_합병 from './C액면금분할_합병/C액면금분할_합병';
import C임원_보수_결정 from './C임원_보수_결정/C임원_보수_결정';
import C임원_보수_한도_승인 from './C임원_보수_한도_승인/C임원_보수_한도_승인';
import C임원_취임_중임_사임_퇴임 from './C임원_취임_중임_사임_퇴임/C임원_취임_중임_사임_퇴임';
import C재무제표_승인 from './C재무제표_승인/C재무제표_승인';
import C전환사채_발행 from './C전환사채_발행/C전환사채_발행';
import C정관변경 from './C정관변경/C정관변경';
import C폼_없음 from './C폼_없음/C폼_없음';

function Agendas() {
  return useObserver(() => (
    <div className={st.item}>
      <div className={st.title}>안건</div>
      <div className={clsx(st.content, Cst.content, vm.state.moreViewYn === true ? Cst.opened : '')}>
        <div className={Cst.agendas}>
          {vm.agendas.map((agenda, index) => (
            <Fragment key={index}>
              {agenda.type === '정관변경' && (
                <C정관변경 agendaIndex={index} agenda={agenda as Agenda<IA정관변경>} moreViewYn={vm.state.moreViewYn} />
              )}
              {agenda.type === '임원_취임_중임_사임_퇴임' && (
                <C임원_취임_중임_사임_퇴임
                  agendaIndex={index}
                  agenda={agenda as Agenda<IA임원_취임_중임_사임_퇴임>}
                  moreViewYn={vm.state.moreViewYn}
                />
              )}
              {agenda.type === '재무제표_승인' && (
                <C재무제표_승인
                  agendaIndex={index}
                  agenda={agenda as Agenda<IA재무제표_승인>}
                  moreViewYn={vm.state.moreViewYn}
                />
              )}
              {agenda.type === '임원_보수_한도_승인' && (
                <C임원_보수_한도_승인
                  agendaIndex={index}
                  agenda={agenda as Agenda<IA임원_보수_한도_승인>}
                  moreViewYn={vm.state.moreViewYn}
                />
              )}
              {agenda.type === '임원_보수_결정' && (
                <C임원_보수_결정
                  agendaIndex={index}
                  agenda={agenda as Agenda<IA임원_보수_결정>}
                  moreViewYn={vm.state.moreViewYn}
                />
              )}
              {agenda.type === '본점의_주소_변경' && (
                <C본점의_주소_변경
                  agendaIndex={index}
                  agenda={agenda as Agenda<IA본점의_주소_변경>}
                  moreViewYn={vm.state.moreViewYn}
                />
              )}
              {agenda.type === '신주발행_유상증자' && (
                <C신주발행_유상증자
                  agendaIndex={index}
                  agenda={agenda as Agenda<IA신주발행_유상증자>}
                  moreViewYn={vm.state.moreViewYn}
                />
              )}
              {agenda.type === '신주발행_무상증자' && (
                <C신주발행_무상증자
                  agendaIndex={index}
                  agenda={agenda as Agenda<IA신주발행_무상증자>}
                  moreViewYn={vm.state.moreViewYn}
                />
              )}
              {agenda.type === '전환사채_발행' && (
                <C전환사채_발행
                  agendaIndex={index}
                  agenda={agenda as Agenda<IA전환사채_발행>}
                  moreViewYn={vm.state.moreViewYn}
                />
              )}
              {(agenda.type === '임원_보수_지급규정_승인' ||
                agenda.type === '임원_퇴직금_지급규정_승인' ||
                agenda.type === '임원_상여금_지급규정_승인' ||
                agenda.type === '차등배당' ||
                agenda.type === '중간배당' ||
                agenda.type === '부동산_매각' ||
                agenda.type === '자금차입' ||
                agenda.type === '자금차입_및_담보제공' ||
                agenda.type === '자회사_설립' ||
                agenda.type === '이사의_자기거래_승인' ||
                agenda.type === '주주총회_안건_자유입력' ||
                agenda.type === '이사회_안건_자유입력' ||
                agenda.type === '임원_무보수_결의' ||
                agenda.type === '이익잉여금처분계산서') && (
                <CommonAgenda
                  agendaIndex={index}
                  agenda={agenda as Agenda<IACommonAgenda>}
                  moreViewYn={vm.state.moreViewYn}
                  sameCount={vm.sameCount(index)}
                />
              )}
              {agenda.type === '액면금분할_합병' && (
                <C액면금분할_합병
                  agendaIndex={index}
                  agenda={agenda as Agenda<IA액면금분할_합병>}
                  moreViewYn={vm.state.moreViewYn}
                />
              )}
              {agenda.type === '폼_없음' && (
                <C폼_없음 agendaIndex={index} agenda={agenda as Agenda<IA폼_없음>} moreViewYn={vm.state.moreViewYn} />
              )}
              {agenda.type === '대표이사_주소_변경' && (
                <C대표이사_주소_변경
                  agendaIndex={index}
                  agenda={agenda as Agenda<IA대표이사_주소_변경>}
                  moreViewYn={vm.state.moreViewYn}
                />
              )}
              {agenda.type === '신규지점_설치' && (
                <C신규지점_설치
                  agendaIndex={index}
                  agenda={agenda as Agenda<IA신규지점_설치>}
                  moreViewYn={vm.state.moreViewYn}
                />
              )}
              {agenda.type === '기존_지점_주소_변경' && (
                <C기존_지점_주소_변경
                  agendaIndex={index}
                  agenda={agenda as Agenda<IA기존_지점_주소_변경>}
                  moreViewYn={vm.state.moreViewYn}
                />
              )}
            </Fragment>
          ))}
          <button
            type="button"
            className={clsx(Cst.moreViewBtn, vm.state.moreViewYn === true ? Cst.opened : '')}
            onClick={() => vm.moreView()}
          >
            <span>{vm.state.moreViewYn ? '줄여보기' : '펼쳐보기'}</span>
          </button>
        </div>
      </div>
    </div>
  ));
}

export default Agendas;
