import { get, post } from '@common/module/httpRequest';

import type {
  FlexLineSubmitApplicationRq,
  LoadFlexLineRs,
  LoadTermRs,
  UserRegistrationTerms,
} from './FlexLineService.interface';

class FlexLineService {
  async loadFlexLine(corpId: string) {
    const res = await get<LoadFlexLineRs>(`/company/${corpId}/alliance/flex-line`);
    const data = res.data;

    return {
      customer: {
        name: data.customer.name || '',
        phoneNumber: data.customer.phoneNumber || '',
        address: data.customer.address || '',
      },
      products:
        data.products?.map((product) => ({
          id: product.id || '',
          name: product.name || '',
          price: product.price || 0,
          cashback: product.cashback || 0,
        })) || [],
      step: data.step || null,
    };
  }

  async flexLineSubmitApplication(corpId: string, param: FlexLineSubmitApplicationRq) {
    await post(`/company/${corpId}/alliance/flex-line`, param);
  }

  async getTermInfo(): Promise<UserRegistrationTerms[]> {
    const res = await get<LoadTermRs>(`/term/KT_FLEX_LINE`);

    return res.data.userRegistrationTerms.map((term) => {
      return {
        id: term.id,
        title: term?.title || '',
        content: term?.content || '',
        required: term?.required ?? false,
      };
    });
  }
}

export default new FlexLineService();
