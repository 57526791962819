import { useObserver } from 'mobx-react';
// import { useEffect } from 'react';
import vm from './AgendaLinkPopupVm';
import st from './AgendaLinkPopup.module.scss';

interface AgendaLinkPopupProps {
  agendaType: string;
}

function AgendaLinkPopup(props: AgendaLinkPopupProps) {
  return useObserver(() => (
    <div className={st.wrap}>
      <div className={st.title}>
        {props.agendaType === '신주발행_유상증자' && (
          <>
            <span className={st.important}>자본금 증액</span>이 필요하면,{' '}
            <span className={st.important}>신주발행 절차</span>를 진행하세요!
          </>
        )}
        {props.agendaType === '임원_취임_중임_사임_퇴임' && (
          <>
            <span className={st.important}>임원 변경</span>을 하려면 <span className={st.important}>등기</span>를 해야
            해요!
          </>
        )}
        {props.agendaType === '등기가_불필요한_정관변경' && (
          <>
            <span className={st.important}>정관 변경</span>이 필요하면{' '}
            <span className={st.important}>주주총회 특별결의</span>를 진행하세요!
          </>
        )}
        {props.agendaType === '본점주소_변경' && (
          <>
            <span className={st.important}>본점주소 변경</span>을 하려면 <span className={st.important}>등기</span>를
            해야 해요!
          </>
        )}
        {props.agendaType === '지점' && (
          <>
            <span className={st.important}>지점 추가, 변경</span>을 하려면 <span className={st.important}>등기</span>를
            해야 해요!
          </>
        )}
        {props.agendaType === '상호' && (
          <>
            <span className={st.important}>상호 변경</span>을 하려면 <span className={st.important}>등기</span>를 해야
            해요!
          </>
        )}
        {props.agendaType === '공고방법' && (
          <>
            <span className={st.important}>공고방법 변경</span>을 하려면 <span className={st.important}>등기</span>를
            해야 해요!
          </>
        )}
        {props.agendaType === '목적' && (
          <>
            <span className={st.important}>목적 변경</span>을 하려면 <span className={st.important}>등기</span>를 해야
            해요!
          </>
        )}
        {props.agendaType === '스톡옵션_규정_신설' && (
          <>
            <span className={st.important}>스톡옵션 규정 신설</span>을 하려면 <span className={st.important}>등기</span>
            를 해야 해요!
          </>
        )}
        {props.agendaType === '스톡옵션_규정_변경' && (
          <>
            <span className={st.important}>스톡옵션 규정 변경</span>을 하려면 <span className={st.important}>등기</span>
            를 해야 해요!
          </>
        )}
        {props.agendaType === '주식양도금지_신설' && (
          <>
            <span className={st.important}>주식 양도제한 규정 신설</span>을 하려면{' '}
            <span className={st.important}>등기</span>를 해야 해요!
          </>
        )}
        {props.agendaType === '폐지하기' && (
          <>
            <span className={st.important}>주식 양도제한 규정 폐지</span>를 하려면{' '}
            <span className={st.important}>등기</span>를 해야 해요!
          </>
        )}
      </div>
      <div className={st.steps}>
        <div className={st.stepWrap}>
          <div className={st.step}>
            {props.agendaType === '신주발행_유상증자' && <div className={st.title}>신주발행 한건 선택!</div>}
            {props.agendaType === '임원_취임_중임_사임_퇴임' && <div className={st.title}>임원 안건 선택!</div>}
            {props.agendaType === '등기가_불필요한_정관변경' && <div className={st.title}>정관 변경 안건 선택!</div>}
            {props.agendaType === '본점주소_변경' && <div className={st.title}>본점주소 변경 안건 선택!</div>}
            {props.agendaType === '지점' && <div className={st.title}>지점 안건 선택!</div>}
            {props.agendaType === '상호' && <div className={st.title}>상호 안건 선택!</div>}
            {props.agendaType === '공고방법' && <div className={st.title}>공고방법 안건 선택!</div>}
            {props.agendaType === '목적' && <div className={st.title}>목적 안건 선택!</div>}
            {props.agendaType === '스톡옵션_규정_신설' && (
              <div className={st.title}>
                스톡옵션 규정 신설
                <br />
                안건 선택!
              </div>
            )}
            {props.agendaType === '스톡옵션_규정_변경' && (
              <div className={st.title}>
                스톡옵션 규정 변경
                <br />
                안건 선택!
              </div>
            )}
            {props.agendaType === '주식양도금지_신설' && (
              <div className={st.title}>
                주식 양도제한 규정 신설
                <br />
                안건 선택!
              </div>
            )}
            {props.agendaType === '주식양도금지_폐지' && (
              <div className={st.title}>
                주식 양도제한 규정 폐지
                <br />
                안건 선택!
              </div>
            )}
            {props.agendaType === '스톡옵션_규정_신설' ||
            props.agendaType === '스톡옵션_규정_변경' ||
            props.agendaType === '주식양도금지_신설' ||
            props.agendaType === '주식양도금지_폐지' ? (
              <div className={st.content}>필수 정보를 입력하세요!</div>
            ) : (
              <div className={st.content}>
                필수 정보를 간단하게
                <br />
                입력하세요!
              </div>
            )}
          </div>
        </div>
        <div className={st.stepWrap}>
          <div className={st.step}>
            <div className={st.title}>내부절차 진행!</div>
            {props.agendaType === '본점주소_변경' || props.agendaType === '지점' ? (
              <div className={st.content}>
                안내에 따라 이사 등의
                <br />
                동의를 받아주세요.
              </div>
            ) : (
              <div className={st.content}>
                안내에 따라 주주 등의
                <br />
                동의를 받아주세요.
              </div>
            )}
          </div>
        </div>
        <div className={st.stepWrap}>
          {props.agendaType === '신주발행_유상증자' && (
            <div className={st.step}>
              <div className={st.title}>등기절차 진행!</div>
              <div className={st.content}>
                안내에 따라 등기를
                <br />
                진행하면 증액 끝!
              </div>
            </div>
          )}
          {(props.agendaType === '임원_취임_중임_사임_퇴임' ||
            props.agendaType === '본점주소_변경' ||
            props.agendaType === '지점' ||
            props.agendaType === '상호' ||
            props.agendaType === '공고방법' ||
            props.agendaType === '목적' ||
            props.agendaType === '스톡옵션_규정_신설' ||
            props.agendaType === '스톡옵션_규정_변경' ||
            props.agendaType === '주식양도금지_신설' ||
            props.agendaType === '주식양도금지_폐지') && (
            <div className={st.step}>
              <div className={st.title}>등기절차 진행!</div>
              <div className={st.content}>
                안내에 따라 등기를 진행
                <br />
                하면 자동 반영되고 끝!
              </div>
            </div>
          )}
          {props.agendaType === '등기가_불필요한_정관변경' && (
            <div className={st.step}>
              <div className={st.title}>도장찍어 서류보관!</div>
              <div className={st.content}>
                <span className={st.important}>주의!</span> 공증 또는 등기가
                <br />
                필요한 경우도 있어요.
              </div>
            </div>
          )}
        </div>
      </div>
      <div className={st.link}>
        {props.agendaType === '신주발행_유상증자' && (
          <a
            href="https://standby.kr/blog/article/%EC%9C%A0%EC%83%81%EC%A6%9D%EC%9E%90%EC%9D%98_%EB%AA%A8%EB%93%A0_%EA%B2%83/"
            target="_blank"
          >
            자본금 증액절차 더 알아보기
          </a>
        )}
        {props.agendaType === '임원_취임_중임_사임_퇴임' && (
          <a
            href="https://standby.kr/blog/article/%EC%9E%84%EC%9B%90_%EB%B3%80%EA%B2%BD%EB%93%B1%EA%B8%B0%EC%9D%98_%EC%A2%85%EB%A5%98/"
            target="_blank"
          >
            임원 변경 절차 더 알아보기
          </a>
        )}
        {props.agendaType === '등기가_불필요한_정관변경' && (
          <a
            href="https://standby.kr/blog/article/%EC%A0%95%EA%B4%80%EB%B3%80%EA%B2%BD5%EB%8B%A8%EA%B3%84/"
            target="_blank"
          >
            정관 변경 절차 더 알아보기
          </a>
        )}
        {props.agendaType === '본점주소_변경' && (
          <a
            href="https://standby.kr/blog/article/%EB%B3%B8%EC%A0%90_%EC%A3%BC%EC%86%8C%EB%B3%80%EA%B2%BD_%EB%AA%A8%EB%93%A0_%EA%B2%83/"
            target="_blank"
          >
            본점주소 변경 절차 더 알아보기
          </a>
        )}
        {props.agendaType === '지점' && (
          <a
            href="https://standby.kr/blog/article/%EB%B2%95%EC%9D%B8_%EC%A7%80%EC%A0%90%EC%9D%98_%EB%AA%A8%EB%93%A0_%EA%B2%83/"
            target="_blank"
          >
            지점 추가, 변경 절차 더 알아보기
          </a>
        )}
        {props.agendaType === '상호' && (
          <a
            href="https://standby.kr/blog/article/%EC%83%81%ED%98%B8%EB%B3%80%EA%B2%BD%EB%93%B1%EA%B8%B0%EC%9D%98_%EB%AA%A8%EB%93%A0_%EA%B2%83/"
            target="_blank"
          >
            상호 변경 절차 더 알아보기
          </a>
        )}
        {props.agendaType === '공고방법' && (
          <a
            href="https://standby.kr/blog/article/%EA%B3%B5%EA%B3%A0%EB%B0%A9%EB%B2%95_%EB%B3%80%EA%B2%BD%EC%9D%98_%EB%AA%A8%EB%93%A0_%EA%B2%83/"
            target="_blank"
          >
            공고방법 변경 절차 더 알아보기
          </a>
        )}
        {props.agendaType === '목적' && (
          <a
            href="https://standby.kr/blog/article/%EB%AA%A9%EC%A0%81%EB%B3%80%EA%B2%BD%EB%93%B1%EA%B8%B0_%EB%AA%A8%EB%93%A0_%EA%B2%83/"
            target="_blank"
          >
            목적 변경 절차 더 알아보기
          </a>
        )}
        {props.agendaType === '스톡옵션_규정_신설' && (
          <a
            href="https://standby.kr/blog/article/%EC%8A%A4%ED%86%A1%EC%98%B5%EC%85%98_%EA%B7%9C%EC%A0%95_%EC%8B%A0%EC%84%A4_%EB%93%B1%EA%B8%B0/"
            target="_blank"
          >
            스톡옵션 규정 신설 절차 더 알아보기
          </a>
        )}
        {props.agendaType === '스톡옵션_규정_변경' && (
          <a
            href="https://standby.kr/blog/article/%EC%8A%A4%ED%86%A1%EC%98%B5%EC%85%98_%EA%B7%9C%EC%A0%95_%EB%B3%80%EA%B2%BD_%EB%93%B1%EA%B8%B0/"
            target="_blank"
          >
            스톡옵션 규정 변경 절차 더 알아보기
          </a>
        )}
        {props.agendaType === '주식양도금지_신설' && (
          <a
            href="https://standby.kr/blog/article/%EC%A3%BC%EC%8B%9D%EC%96%91%EB%8F%84%EC%A0%9C%ED%95%9C_%EA%B7%9C%EC%A0%95_%EC%8B%A0%EC%84%A4/"
            target="_blank"
          >
            주식 양도제한 규정 신설 절차 절차 더 알아보기
          </a>
        )}
        {props.agendaType === '주식양도금지_폐지' && (
          <a
            href="https://standby.kr/blog/article/%EC%A3%BC%EC%8B%9D%EC%96%91%EB%8F%84%EC%A0%9C%ED%95%9C_%EA%B7%9C%EC%A0%95_%ED%8F%90%EC%A7%80/"
            target="_blank"
          >
            주식 양도제한 규정 폐지 절차 절차 더 알아보기
          </a>
        )}
      </div>
      <div className={st.buttonArea}>
        <a
          href={
            props.agendaType === '지점'
              ? `/corp/agenda/step/AGENDA/?corp=${vm.id}`
              : `/corp/agenda/step/AGENDA/?corp=${vm.id}&agendaType=${props.agendaType}`
          }
        >
          {props.agendaType === '신주발행_유상증자' && '신주발행 절차 시작하기'}
          {props.agendaType === '임원_취임_중임_사임_퇴임' && '임원 변경 절차 시작하기'}
          {props.agendaType === '등기가_불필요한_정관변경' && '정관 변경 절차 시작하기'}
          {props.agendaType === '본점주소_변경' && '본점주소 변경 절차 시작하기'}
          {props.agendaType === '지점' && '지점 추가, 변경 절차 시작하기'}
          {props.agendaType === '상호' && '상호 변경 절차 시작하기'}
          {props.agendaType === '공고방법' && '공고방법 변경 절차 시작하기'}
          {props.agendaType === '목적' && '목적 변경 절차 시작하기'}
          {props.agendaType === '스톡옵션_규정_신설' && '스톡옵션 규정 신설 절차 시작하기'}
          {props.agendaType === '스톡옵션_규정_변경' && '스톡옵션 규정 변경 절차 시작하기'}
          {props.agendaType === '주식양도금지_신설' && '주식 양도제한 규정 신설 절차 시작하기'}
          {props.agendaType === '주식양도금지_폐지' && '주식 양도제한 규정 폐지 절차 시작하기'}
        </a>
      </div>
      <div className={st.additionalEx}>누르면 안건 페이지로 이동해요.</div>
    </div>
  ));
}
export default AgendaLinkPopup;
