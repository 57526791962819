import regExp from '@common/module/regExp';
import { addCommas } from '@common/module/replaceNumber';
import { Product } from '@service/FlexLine/FlexLineService.interface';
import { Pen, Shield } from '@standby/dike_ui/assets/icon/fill';
import { ArrowRight } from '@standby/dike_ui/assets/icon/line';
import { CheckBox, Error, TextareaField, TextButton, TextField, Typography } from '@standby/dike_ui/components/atoms';
import { ErrorValidation } from '@standby/dike_ui/components/molecules';
import color from '@standby/dike_ui/design-token/color.module.scss';
import clsx from 'clsx';
import { observer } from 'mobx-react';

import { BeforeApplicationSubmissionBundle } from '../../useBeforeApplicationSubmission';
import { TermBundle } from '../../useTerm';
import st from './stype.module.scss';

const FormInput = observer(
  ({
    products,
    termBundle,
    beforeApplicationSubmissionBundle,
  }: {
    products: Product[];
    termBundle: TermBundle;
    beforeApplicationSubmissionBundle: BeforeApplicationSubmissionBundle;
  }) => {
    const { termInfos, isAllAgreed, isRequiredTermsAgreed, agreeTermByIndex, agreeAllTerms, openTermDetailPopup } =
      termBundle;
    const { formMob, submissionRejected } = beforeApplicationSubmissionBundle;

    return (
      <div className={st.infoInputWrap}>
        <img src="/images/flexLineFormTitle.svg" alt="KT FlexLine" />
        <div className={st.infoInputs}>
          <div className={st.infoInput}>
            <div className={st.titleWrap}>
              <div className={st.title}>
                <div className={st.icon}>
                  <Shield width="16" height="16" fill={color.grayScale000} />
                </div>
                <Typography fontType="Title-18B" className={st.titleText}>
                  상품 옵션
                </Typography>
              </div>
              <a href="https://standby.kr/blog/article/KT_flexline/" target="_blank">
                <TextButton underline className={st.link} type="button">
                  디지털 자산 보호, KT Flexline인 이유
                  <ArrowRight fill={color.grayScale700} height={16} width={16} />
                </TextButton>
              </a>
            </div>
            <div className={st.radioList}>
              {products.map((product) => (
                <label key={product.id} className={formMob.product === product.id ? st.active : ''}>
                  <input
                    type="radio"
                    name="product"
                    value={product.id}
                    checked={formMob.product === product.id}
                    onChange={(e) => formMob.onChange(e, 'product')}
                  />
                  <Typography fontType="Body-16M" className={st.name}>
                    {product.name}
                  </Typography>
                  <Typography fontType="Body-14R" className={st.price}>
                    월 {addCommas(product.price)}원
                  </Typography>
                </label>
              ))}
            </div>
            <div className={st.tip}>
              <Typography fontType="Body-14M">구성 </Typography>
              <Typography fontType="Body-14R">
                &nbsp;: 초고속인터넷 전용회선 + 고정 IP 2개 + 포티넷 방화벽 + 운용 관제
              </Typography>
            </div>
          </div>
          <div className={st.infoInput}>
            <div className={st.titleWrap}>
              <div className={st.title}>
                <div className={st.icon}>
                  <Pen width="16" height="16" fill={color.grayScale000} />
                </div>
                <Typography fontType="Title-18B" className={st.titleText}>
                  상담정보
                </Typography>
              </div>
            </div>
            <div className={st.inputs}>
              <TextField
                name="name"
                label="이름"
                style={{ width: '452px' }}
                placeholder="홍길동"
                labelAsteriskMark
                value={formMob.name}
                onChange={(e) => formMob.onChange(e, 'name')}
                error={
                  <ErrorValidation
                    name="name"
                    value={formMob.name}
                    errorCase={{
                      required: '이름을 입력해 주세요.',
                      pattern: {
                        value: regExp.onlyKorEng().test(formMob.name),
                        message: '한글 또는 영문으로 입력해 주세요.',
                      },
                    }}
                  />
                }
              />
              <TextField
                name="phoneNumber"
                label="전화번호"
                style={{ width: '452px' }}
                placeholder="01012345678"
                labelAsteriskMark
                value={formMob.phoneNumber}
                onChange={(e) => formMob.onChange(e, 'phoneNumber')}
                error={
                  <ErrorValidation
                    name="phoneNumber"
                    value={formMob.phoneNumber}
                    errorCase={{
                      required: '전화번호를 입력해 주세요.',
                      pattern: {
                        value: regExp.phoneNumber().test(formMob.phoneNumber),
                        message: '올바른 전화번호를 입력해 주세요.',
                      },
                    }}
                  />
                }
              />
              <TextField
                name="address"
                label="설치주소"
                value={formMob.address}
                onChange={(e) => formMob.onChange(e, 'address')}
              />
              <TextareaField
                name="inquiry"
                label="문의내용"
                placeholder="궁금한 내용을 편하게 입력해 주세요!"
                value={formMob.inquiry}
                textAreaClassName={st.inquiryTextarea}
                onChange={(e) => formMob.onChange(e, 'inquiry')}
              />
              <div className={st.termWrap}>
                <CheckBox asteriskMark checked={isAllAgreed} onChange={(e) => agreeAllTerms(e.target.checked)}>
                  모두 동의합니다
                </CheckBox>
                <ul className={st.termsList}>
                  {termInfos.map((item, idx) => {
                    return (
                      <li key={idx}>
                        <CheckBox
                          size="small"
                          name="terms"
                          value={item.id}
                          checked={item.agreeYn}
                          onChange={(e) => agreeTermByIndex(e.target.checked, idx)}
                        >
                          {item.title}
                        </CheckBox>
                        {item.content && (
                          <TextButton
                            type="button"
                            className={st.textBtn}
                            fontType="Detail-12M"
                            underline
                            onClick={() =>
                              openTermDetailPopup(
                                item.title,
                                <div
                                  className={clsx(st.agreeContent, 'terms')}
                                  dangerouslySetInnerHTML={{ __html: item.content }}
                                />,
                              )
                            }
                          >
                            상세보기
                          </TextButton>
                        )}
                      </li>
                    );
                  })}
                </ul>
                {submissionRejected && !isRequiredTermsAgreed && <Error>필수 약관에 동의해주세요.</Error>}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  },
);

export default FormInput;
