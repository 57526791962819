import regExp from '@common/module/regExp';
import { onlyNumber } from '@common/module/replaceNumber';
import { useFlexLinePostMutation } from '@pages/Corp/FlexLine/queries';
import { Customer } from '@service/FlexLine/FlexLineService.interface';
import { FlexLineSubmitApplicationRq } from '@service/FlexLine/FlexLineService.interface';
import formDataToJson from '@src/common/module/submit';
import { useLocalObservable } from 'mobx-react';
import React, { useState } from 'react';

interface FormMob {
  product: string;
  name: string;
  phoneNumber: string;
  address: string;
  inquiry: string;
  onChange: (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    key: 'product' | 'name' | 'phoneNumber' | 'address' | 'inquiry',
  ) => void;
}

export interface BeforeApplicationSubmissionBundle {
  formMob: FormMob;
  onSubmitForm: (e: React.FormEvent<HTMLFormElement>) => Promise<void>;
  submissionRejected: boolean;
}

const useBeforeApplicationSubmission = (
  customerData: Customer,
  isRequiredTermsAgreed: boolean,
  corpId: string,
): BeforeApplicationSubmissionBundle => {
  const [submissionRejected, setSubmissionRejected] = useState<boolean>(false);
  const flexLinePostMutation = useFlexLinePostMutation(corpId);

  const formMob = useLocalObservable<FormMob>(() => ({
    product: 'B',
    name: customerData.name,
    phoneNumber: customerData.phoneNumber,
    address: customerData.address,
    inquiry: '',
    onChange(e, key) {
      if (key === 'phoneNumber') {
        this[key] = regExp.deleteSpace(onlyNumber(e.target.value).toString());
      } else {
        this[key] = e.target.value;
      }
    },
  }));

  const onSubmitForm = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const formData = formDataToJson(e.target);

    if (formData.error || !isRequiredTermsAgreed) {
      setSubmissionRejected(true);
      window.scrollTo(0, document.body.scrollHeight);

      return;
    }

    flexLinePostMutation.mutate(formData.data as FlexLineSubmitApplicationRq);
  };

  return {
    formMob,
    onSubmitForm,
    submissionRejected,
  };
};

export default useBeforeApplicationSubmission;
