import { runInAction, observable } from 'mobx';
import meetingsStepModel from '@model/Corp/Agenda/Step/meetingsStepModel';
import corpModel from '@model/Corp/corpModel';
import regExp from '@common/module/regExp';
import meetingsService from '@service/Corp/Agenda/Step/meetingsService';
import portalModel from '@model/portalModel';
import TermsModal from '@pages/Auth/TermsModal/TermsModal';
import st from './RegistrationAgency.module.scss';
import clsx from 'clsx';

interface State {
  requestRequirementYn: boolean;
  termsRequiredCheckYn: boolean;
  terms: {
    id: number;
    required: boolean;
    title: string;
    content: string;
  }[];
}

class RegistrationAgencyVm {
  state: State = observable({
    requestRequirementYn: false,
    termsRequiredCheckYn: false,
    terms: [],
  });
  async load() {
    const data = await meetingsService.loadTerms();
    this.state.terms = data.userRegistrationTerms.map((item) => {
      return {
        id: item.id || -1,
        required: item.required || false,
        title: item.title || '',
        content: item.content || '',
      };
    });
  }
  corpRegProgressType(idx: number) {
    return meetingsStepModel.tabList[idx].corpRegProgressType;
  }
  setCorpRegProgressType(e: React.ChangeEvent<HTMLInputElement>, idx: number) {
    runInAction(() => {
      meetingsStepModel.tabList[idx].corpRegProgressType = e.target.value;
    });
  }
  jointCertificate(idx: number) {
    return meetingsStepModel.tabList[idx]?.jointCertificate;
  }
  setJointCertificateUseYn(e: React.ChangeEvent<HTMLInputElement>, idx: number) {
    const jointCertificate = meetingsStepModel.tabList[idx]?.jointCertificate;

    if (jointCertificate) {
      runInAction(() => {
        jointCertificate.useYn = e.target.value === 'true';
      });
    }
  }
  requestRequirement(idx: number) {
    return meetingsStepModel.tabList[idx].requestRequirement;
  }
  setRequestRequirement(e: React.ChangeEvent<HTMLTextAreaElement>, idx: number) {
    runInAction(() => {
      meetingsStepModel.tabList[idx].requestRequirement = e.target.value;
    });
  }
  personInCharge(idx: number) {
    return meetingsStepModel.tabList[idx].personInCharge;
  }
  setPersonInCharge(e: React.ChangeEvent<HTMLInputElement>, idx: number, type: string) {
    runInAction(() => {
      if (type === 'name') {
        meetingsStepModel.tabList[idx].personInCharge.name = e.target.value;
      } else if (type === 'phoneNum') {
        meetingsStepModel.tabList[idx].personInCharge.phoneNum = e.target.value;
      } else if (type === 'email') {
        meetingsStepModel.tabList[idx].personInCharge.email = regExp.deleteSpace(e.target.value);
      }
    });
  }
  telephoneCounselingYn(idx: number) {
    return meetingsStepModel.tabList[idx].telephoneCounselingYn;
  }
  setTelephoneCounselingYn(e: React.ChangeEvent<HTMLInputElement>, idx: number) {
    runInAction(() => {
      meetingsStepModel.tabList[idx].telephoneCounselingYn = e.target.checked;
    });
  }
  informationProvideYn(idx: number) {
    return meetingsStepModel.tabList[idx].informationProvideYn;
  }
  setInformationProvideYn(e: React.ChangeEvent<HTMLInputElement>, idx: number) {
    runInAction(() => {
      meetingsStepModel.tabList[idx].informationProvideYn = e.target.checked;
    });
  }
  get companyName() {
    const company = corpModel.company;
    let companyName = '';

    if (company.prefixedYn) {
      if (company.corpType === '주식회사') {
        companyName = `(주)${company.companyName}`;
      } else if (company.corpType === '유한회사' || company.corpType === '유한책임회사') {
        companyName = `(유)${company.companyName}`;
      }
    } else {
      if (company.corpType === '주식회사') {
        companyName = `${company.companyName}(주)`;
      } else if (company.corpType === '유한회사' || company.corpType === '유한책임회사') {
        companyName = `${company.companyName}(유)`;
      }
    }

    return companyName;
  }
  openRequirement() {
    this.state.requestRequirementYn = !this.state.requestRequirementYn;
  }
  companyShareholderList(idx: number) {
    return meetingsStepModel.tabList[idx]?.digitalCertificate?.companyShareholderList;
  }
  setCompanyUseType(e: React.ChangeEvent<HTMLInputElement>, idx: number, idx2: number) {
    const companyShareholderList = meetingsStepModel.tabList[idx]?.digitalCertificate?.companyShareholderList;

    if (companyShareholderList) {
      runInAction(() => {
        companyShareholderList[idx2].useType = e.target.value;
      });
    }
  }
  digitalCertificate(idx: number) {
    return meetingsStepModel.tabList[idx]?.digitalCertificate;
  }
  setUseType(e: React.ChangeEvent<HTMLInputElement>, idx: number) {
    const digitalCertificate = meetingsStepModel.tabList[idx]?.digitalCertificate;

    if (digitalCertificate) {
      runInAction(() => {
        digitalCertificate.company.useType = e.target.value;
      });
    }
  }
  termsPopup() {
    const findIdx = this.state.terms.findIndex((item) => item.title === '제3자 정보제공에 동의');
    runInAction(() => {
      portalModel.title = this.state.terms[findIdx].title;
      portalModel.content = (
        <TermsModal
          content={
            <div
              className={clsx(st.termsContent, 'terms')}
              dangerouslySetInnerHTML={{ __html: this.state.terms[findIdx].content }}
            ></div>
          }
        />
      );
    });
  }
}

export default new RegistrationAgencyVm();
