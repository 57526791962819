import { observer } from 'mobx-react';
import queryString from 'query-string';
import { useLocation } from 'react-router-dom';

import AfterApplicationSubmission from './components/AfterApplicationSubmission';
import BeforeApplicationSubmission from './components/BeforeApplicationSubmission';
import useFlexLine from './useFlexLine';

const FlexLine = observer(() => {
  const location = useLocation();
  const corpId = String(queryString.parse(location.search).corp);

  const { flexlineQueryResult } = useFlexLine(corpId);

  return flexlineQueryResult.isSuccess ? (
    <>
      {flexlineQueryResult.data.step ? (
        <AfterApplicationSubmission step={flexlineQueryResult.data.step} corpId={corpId} />
      ) : (
        <BeforeApplicationSubmission
          customer={flexlineQueryResult.data.customer}
          products={flexlineQueryResult.data.products}
          corpId={corpId}
        />
      )}
    </>
  ) : (
    <></>
  );
});

export default FlexLine;
