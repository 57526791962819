import Box1040 from '@commonComponents/Box1040/Box1040';
import Content from '@commonComponents/Content/Content';
import PageMainTitle from '@commonComponents/PageMainTitle';
import Faq from '@pages/Corp/FlexLine/components/BeforeApplicationSubmission/components/Faq';
import { Confirm } from '@src/model/newDialogModel';
import HeaderSidebar from '@src/pages/Corp/Side/HeaderSidebar/HeaderSidebar';
import { Star } from '@standby/dike_ui/assets/icon/fill';
import { ArrowRight } from '@standby/dike_ui/assets/icon/line';
import { IconButton } from '@standby/dike_ui/components/atoms';
import { Typography } from '@standby/dike_ui/components/atoms';
import color from '@standby/dike_ui/design-token/color.module.scss';
import { useObserver } from 'mobx-react';

import st from './style.module.scss';

function FlexLine() {
  const joinConfirm = async () => {
    if (
      await Confirm(
        '스탠바이 회원가입 후 신청 가능해요!',
        <Typography fontType="Body-14R" className={st.confirmText}>
          KT 랜섬웨어 방화벽 혜택은 스탠바이 등록법인에게만 제공되는
          <br />
          혜택이에요. 회원가입 후 혜택 신청과 함께 다양한 무료 법인 관리 기능을 이용해보세요.
        </Typography>,
        '회원가입',
        '닫기',
      )
    ) {
      window.location.href = '/auth/joinmembership';
    }
  };

  return useObserver(() => (
    <div className={st.flexLine}>
      <HeaderSidebar companyName="" flexLineActiveYn={true} />
      <Content>
        <Box1040>
          <PageMainTitle
            icon={<Star width="24" height="24" fill={color.primary800} />}
            title="KT 랜섬웨어 방화벽"
            rightContent={
              <IconButton size="small" rightIcon="ArrowRight" color="primary400" type="button" onClick={joinConfirm}>
                KT 랜섬웨어 방화벽 상담 신청하기
              </IconButton>
            }
          />
          <img
            src="/images/flexLine_Introduction.png"
            className={st.flexLineIntroduction}
            alt="KT 랜섬웨어 방화벽 소개"
          />
          <Faq />
          <div className={st.btnArea}>
            <button type="button" onClick={joinConfirm}>
              <Typography fontType="Title-24B" className={st.btnText}>
                KT 랜섬웨어 방화벽 상담 신청하기
                <ArrowRight fill={color.grayScale000} height={20} width={20} />
              </Typography>
            </button>
          </div>
        </Box1040>
      </Content>
    </div>
  ));
}

export default FlexLine;
