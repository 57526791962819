import type {
  BusinessRegistration,
  BusinessRegistrationStatuses,
  Representatives,
  CorporateLeaseAgreementFiles,
  MajorShareholderRelationships,
  SelectableBusinessPurposes,
  TypeOfBusinesses,
  PermitFiles,
  Guide,
  Information,
  BusinessTypeAndItemList,
  Bankbooks,
  BusinessRegistrationDocument,
  ViewingPeriodFinishedYn,
} from './businessRegistrationModel.interface';

import { makeObservable, observable } from 'mobx';

class businessRegistrationModelClass implements BusinessRegistration {
  constructor() {
    makeObservable(this, {
      businessRegistrationStatuses: observable,
      companyName: observable,
      establishedAt: observable,
      dateOfBusinessCommencement: observable,
      representatives: observable,
      corporateLeaseAgreementFiles: observable,
      majorShareholderRelationships: observable,
      selectableBusinessPurposes: observable,
      typeOfBusinesses: observable,
      permitFiles: observable,
      hometaxDelegationAcceptanceYn: observable,
      bookkeepingServiceApplyYn: observable,
      filingReceiptFile: observable,
      filingReceiptThumbnailFile: observable,
      businessRegistrationThumbnailFile: observable,
      businessRegistrationNumber: observable,
      rosterListDownloadLink: observable,
      cssn: observable,
      pageType: observable,
      businessRegistrationFile: observable,
      guides: observable,
      fileDownloadUrl: observable,
      issuedAt: observable,
      information: observable,
      businessTypeAndItemList: observable,
      freeMonth: observable,
      bankbooks: observable,
      businessRegistration: observable,
      viewingPeriodFinishedYn: observable,
    });
  }
  businessRegistrationStatuses: BusinessRegistrationStatuses[] = [];
  companyName = '';
  establishedAt = '';
  dateOfBusinessCommencement = '';
  representatives: Representatives[] = [];
  corporateLeaseAgreementFiles: CorporateLeaseAgreementFiles[] = [];
  majorShareholderRelationships: MajorShareholderRelationships[] | null = null;
  selectableBusinessPurposes: SelectableBusinessPurposes[] = [];
  typeOfBusinesses: TypeOfBusinesses[] = [];
  permitFiles: PermitFiles[] = [];
  hometaxDelegationAcceptanceYn: boolean | null = null;
  bookkeepingServiceApplyYn: boolean | null = null;
  filingReceiptFile = '';
  filingReceiptThumbnailFile = '';
  businessRegistrationThumbnailFile = '';
  businessRegistrationNumber = '';
  rosterListDownloadLink = '';
  cssn = '';
  pageType: 'ONLY_FILE' | 'INCLUDE_STEP' | 'REGISTERING' | 'BEFORE_UPLOAD_FILE' | 'COMPLETE_HOME' | null = null;
  businessRegistrationFile = '';
  guides: Guide[] = [];
  fileDownloadUrl = '';
  issuedAt = '';
  information: Information = {
    businessRegistrationNumber: '',
    establishedAt: '',
    businessStartAt: '',
    businessYear: '',
  };
  businessTypeAndItemList: BusinessTypeAndItemList[] = [];
  freeMonth = '';
  bankbooks: Bankbooks[] = [];
  businessRegistration: BusinessRegistrationDocument = {
    companyName: '',
    businessRegistrationNumber: '',
  };
  viewingPeriodFinishedYn: ViewingPeriodFinishedYn = false;
}

export default new businessRegistrationModelClass();
