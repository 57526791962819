import formDataToJson from '@common/module/submit';
import type { SelectedAgenda } from '@model/Corp/Agenda/Step/agendaStepModel.interface';
import agendaService from '@service/Corp/Agenda/agendaService';
import meetingsService from '@service/Corp/Agenda/Step/meetingsService';
import type { NewStockInfoParam, ShareholderListParam } from '@service/Corp/Agenda/Step/meetingsService.interface';
import { observable } from 'mobx';

interface State {
  submitYn: boolean;
}

class AgendaQuestionVm {
  state: State = observable({
    submitYn: false,
  });
  async submit(
    e: React.FormEvent<HTMLFormElement>,
    openFirst: () => void,
    pageType = '안건질문',
    agendaProgressId: string | null = null,
  ) {
    e.preventDefault();

    const formData = formDataToJson(e.target);

    if (formData.error) {
      return;
    }

    this.state.submitYn = true;
    const param = formData.data;
    const paramFormData = new FormData(e.target as HTMLFormElement);

    try {
      switch (pageType) {
        case '안건질문':
          if (paramFormData.get('agendaDetail.agendaType') === '임원_취임_중임_사임_퇴임') {
            await agendaService.saveAgenda(paramFormData, agendaProgressId, true);
          } else {
            await agendaService.saveAgenda(paramFormData, agendaProgressId);
          }

          break;
        case '주주총회_방식':
        case '정기주주총회_방식':
          await meetingsService.saveShareholdersMeetingMethod(param, agendaProgressId);
          break;
        case '주주총회_정보입력':
        case '주주총회_대표결정_정보입력':
        case '주주전원서면결의_정보입력':
        case '주주총회_이사회_정보입력':
          await meetingsService.saveShareholdersMeetingInfo(param, agendaProgressId);
          break;
        case '이사회_정보입력':
        case '대표_결정사항_정보입력':
          await meetingsService.saveBoardOfDirectorsInfo(param, agendaProgressId);
          break;
        case '등기_방법': //등기 방법 선택
          await meetingsService.saveRegistrationAgency(param, agendaProgressId);
          break;
        case '신주발행_방식':
          await meetingsService.saveNewStockInfo(param as NewStockInfoParam, agendaProgressId);
          break;
        case '주주명부':
          await meetingsService.saveShareholderList(param as ShareholderListParam, agendaProgressId);
          break;
      }

      openFirst();
    } catch (err) {
      this.state.submitYn = false;
    } finally {
      this.state.submitYn = false;
    }
  }
  subTitleBadgeText(selectedAgenda: SelectedAgenda) {
    const registrationRequiredYn = selectedAgenda.registrationRequiredYn;
    const 의결기관 = selectedAgenda.의결기관;
    let returnText = registrationRequiredYn ? '등기 필요' : '등기 불필요';

    if (의결기관) {
      returnText += ' · ' + 의결기관;
    }

    return returnText;
  }
  tooltipText(agendaType: string | undefined) {
    switch (agendaType) {
      case '재무제표_승인':
        return '주식회사는 매년 정기주주총회에서 재무제표를 승인해야 해요. (상법 제449조) 등기를 하거나 주주총회 의사록을 공증할 필요는 없고, 주주총회 승인만 하면 되어요! 이를 진행하지 않으면 과태료과 부과될 수 있으니, 매년 꼭 진행해주세요.';
        break;
      case '임원_보수_한도_승인':
        return '이사, 감사, 대표의 보수는 정관에서 정하지 않으면, 주주총회에서 매번 승인해야 해요. 그리고 주주총회에서는 한도만 정해두고, 구체적인 액수는 이사회에서 정하는 경우가 더 많아요. 주주총회는 많은 주주들이 참석해서 절차가 까다롭고, 보안유지도 덜 되니까요.';
        break;
      case '임원_보수_결정':
        return '이사, 감사, 대표의 보수는 정관에서 정하지 않으면, 주주총회에서 매번 승인해야 해요. 이사회가 열리지 않는 회사라면, 주주총회에서 구체적 보수액을 정하고, 이사회가 열리는 회사라면, 이사회에서 구체적 보수액을 정하는 것이 일반적이에요.';
        break;
      default:
        return '';
    }
  }
}

export default new AgendaQuestionVm();
