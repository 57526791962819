import FileUploadDikeUI from '@src/pages/common/FileUpload/FileUploadDikeUI';
import { CompanyBuild } from '@standby/dike_ui/assets/icon/fill';
import { Button, GuideTitle, Label, TextField, Typography } from '@standby/dike_ui/components/atoms';
import { ErrorValidation } from '@standby/dike_ui/components/molecules';
import color from '@standby/dike_ui/design-token/color.module.scss';
import clsx from 'clsx';
import { useObserver } from 'mobx-react';
import { useEffect, useState } from 'react';

import st from './CompanyRegistration.module.scss';
import CompanyRegistrationVm from './CompanyRegistrationVm';

function CompanyRegistration() {
  const [vm] = useState(new CompanyRegistrationVm());

  useEffect(() => {
    setTimeout(() => {
      vm.state.action = true;
    }, 700);
  }, []);

  return useObserver(() => (
    <>
      <div className={clsx(st.joinBackground, vm.state.action ? st.action : null)}>
        <div className={st.firecracker}>
          <img src="/images/firecracker/1.svg" />
          <img src="/images/firecracker/2.svg" />
          <img src="/images/firecracker/3.svg" />
          <img src="/images/firecracker/4.svg" />
          <img src="/images/firecracker/5.svg" />
          <img src="/images/firecracker/6.svg" />
          <img src="/images/firecracker/7.svg" />
          <img src="/images/firecracker/8.svg" />
        </div>
        <div className={st.square}>
          <div />
          <div />
          <div />
          <div />
          <div />
          <div />
          <div />
          <div />
          <div />
          <div />
          <div />
        </div>
      </div>
      <div className={st.companyRegistraion}>
        <img src="/images/companyRegistration_info.svg?1" />
        <form onSubmit={(e) => vm.submit(e)} autoComplete="off" className={st.form}>
          <div className={st.row}>
            {vm.state.company.companyName ? (
              <>
                <Label asteriskMark style={{ marginBottom: '2px' }}>
                  한글 회사 이름을 입력하세요.
                </Label>
                <button type="button" className={st.companyInfo} onClick={() => vm.openCompanyNameSearch()}>
                  <Typography fontType="Body-14R" className={st.companyTitle}>
                    <CompanyBuild width="16" height="16" fill={color.primary400} />
                    {vm.state.company.companyName}
                  </Typography>
                  <Typography fontType="Detail-12M" className={st.companyAddress}>
                    {vm.state.company.address}
                  </Typography>
                </button>
              </>
            ) : (
              <TextField
                label="한글 회사 이름을 입력하세요."
                labelAsteriskMark
                name="company.companyName"
                type="text"
                value={vm.state.company.companyName}
                onClick={() => vm.openCompanyNameSearch()}
                error={
                  <ErrorValidation
                    name="company.companyName"
                    value={vm.state.company.companyName}
                    errorCase={{
                      required: '한글 회사 이름을 입력해주세요.',
                    }}
                  />
                }
              />
            )}
            <input type="hidden" name="company.companyName" value={vm.state.company.companyName} />
            <input type="hidden" name="company.companyType" value={vm.state.company.companyType} />
            <input type="hidden" name="company.address" value={vm.state.company.address} />
            <input type="hidden" name="company.office" value={vm.state.company.office} />
            <input type="hidden" name="company.registerNum" value={vm.state.company.registerNum} />
          </div>
          <div className={st.row}>
            <div className={st.title}>
              <Label asteriskMark>주주명부를 업로드하세요.</Label>
              <GuideTitle title="주주명부 원스톱 관리의 장점" arrowRight onClick={() => vm.shareholderListPopup()} />
            </div>
            <FileUploadDikeUI name="stockholderFiles" color="secondary" />
          </div>
          <div className={st.row}>
            <div className={st.title}>
              <Label asteriskMark>최신정관을 업로드하세요.</Label>
              <GuideTitle title="정관 원스톱 관리의 장점" arrowRight onClick={() => vm.aoaPopup()} />
            </div>
            <FileUploadDikeUI name="aoaFiles" color="secondary" />
          </div>
          <div className={st.row}>
            <div className={st.title}>
              <Label asteriskMark>사업자등록증을 업로드하세요.</Label>
            </div>
            <FileUploadDikeUI
              name="businessRegistrationFile"
              color="secondary"
              multiple={false}
              accept=".jpg, .jpeg, .png, .pdf"
            />
          </div>
          <div className={st.btnArea}>
            <Button type="submit" className={st.squareBtn} size="large" color="primary400">
              이메일&카톡으로 처리하는 주주총회 시작하기
            </Button>
            <Typography fontType="Body-14R" className={st.btnExplain}>
              곧바로 법인 등기부등본 1부를 무료로 발급해드려요!
            </Typography>
          </div>
        </form>
      </div>
    </>
  ));
}

export default CompanyRegistration;
