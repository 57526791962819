import type { SelectedAgenda } from '@model/Corp/Agenda/Step/agendaStepModel.interface';
import qst from '@pages/Corp/Agenda/Step/AgendaQuestion/commonQuestion.module.scss';
import type {
  I신규지점,
  I신규지점_설치,
  SelectableMetropolitanType,
} from '@src/service/Corp/Agenda/agendaService.interface';
import AddBtn from '@standby/common-ui/components/atoms/Button/AddBtn/AddBtn';
import MinusBtn from '@standby/common-ui/components/atoms/Button/MinusBtn/MinusBtn';
import Select from '@standby/common-ui/components/atoms/Input/Select/Select';
import Error from '@standby/common-ui/components/molecules/ErrorValidation/ErrorValidation';
import { runInAction } from 'mobx';
import { observer, useLocalObservable } from 'mobx-react';
import React, { useEffect } from 'react';

import st from './style.module.scss';

interface Props {
  agenda: SelectedAgenda<I신규지점_설치>;
  agendaIndex: number;
}

interface Mob {
  selectableMetropolitanTypes: SelectableMetropolitanType[];
  branches: I신규지점[];
  getBranch: (index: number) => I신규지점;
  addBranch: () => void;
  deleteBranch: (index: number) => void;
  selectSidoType: (index: number, e: React.ChangeEvent<HTMLSelectElement>) => void;
  selectSigungu: (index: number, e: React.ChangeEvent<HTMLSelectElement>) => void;
}

const EstablishNewBranch = observer(function EstablishNewBranch(props: Props) {
  const {
    agenda: { agendaDetail },
  } = props;

  const mob = useLocalObservable<Mob>(() => ({
    selectableMetropolitanTypes: [],
    branches: [],
    getBranch(index: number): I신규지점 {
      return this.branches[index];
    },
    addBranch(): void {
      this.branches.push({
        metropolitanType: '',
        sigungu: '',
      });
    },
    deleteBranch(index: number): void {
      this.branches.splice(index, 1);
    },
    selectSidoType(index: number, e: React.ChangeEvent<HTMLSelectElement>): void {
      if (this.branches[index]) {
        this.branches[index].metropolitanType = e.target.value;
      } else {
        this.branches[index] = {
          metropolitanType: e.target.value,
          sigungu: '',
        };
      }
    },
    selectSigungu(index: number, e: React.ChangeEvent<HTMLSelectElement>): void {
      if (this.branches[index]) {
        this.branches[index].sigungu = e.target.value;
      }
    },
  }));

  useEffect(() => {
    runInAction(() => {
      mob.branches = [];

      if (agendaDetail.branches.length > 0) {
        mob.branches = [...agendaDetail.branches];
      } else {
        mob.addBranch();
      }

      mob.selectableMetropolitanTypes = [...agendaDetail.selectableMetropolitanTypes];
    });
  }, [agendaDetail]);

  return (
    <div className={qst.questionForm}>
      <div className={qst.qusetion}>
        <div className={qst.questionTitle}>신규 지점 주소의 시/군/구를 선택하세요.</div>
        <div className={st.column}>
          {mob.branches.map((branch, index) => (
            <Row key={index} index={index} branch={branch} mob={mob} />
          ))}
          <AddBtn className={st.addBtn} onClick={() => mob.addBranch()}>
            추가하기
          </AddBtn>
        </div>
      </div>
    </div>
  );
});

interface RowProps {
  index: number;
  branch: I신규지점;
  mob: Mob;
}

const Row = observer(function Row(props: RowProps) {
  const { index, branch, mob } = props;

  const selectedSidoType = branch.metropolitanType || '';
  const selectedSigungu = branch.sigungu || '';
  const selectableSigungus =
    mob.selectableMetropolitanTypes.find((metropolitanType) => metropolitanType.metropolitanType === selectedSidoType)
      ?.selectableSigungus || [];

  return (
    <div className={st.row}>
      <span className={st.name}>신규 지점{index + 1}</span>
      <div className={st.selectRow}>
        <Select
          name={`agendaDetail.branches[${index}].metropolitanType`}
          value={selectedSidoType}
          onChange={(e) => mob.selectSidoType(index, e)}
          style={{ width: '220px' }}
          errorText={
            <Error
              name={`agendaDetail.branches[${index}].metropolitanType`}
              value={selectedSidoType}
              errorCase={{
                required: '시/도를 선택해주세요.',
              }}
            />
          }
        >
          <option value="">시/도</option>
          {mob.selectableMetropolitanTypes.map((metropolitanType) => (
            <option key={metropolitanType.metropolitanType} value={metropolitanType.metropolitanType}>
              {metropolitanType.metropolitanType}
            </option>
          ))}
        </Select>
        <Select
          name={`agendaDetail.branches[${index}].sigungu`}
          value={selectedSigungu}
          onChange={(e) => mob.selectSigungu(index, e)}
          style={{ width: '220px' }}
        >
          <option value="">시/군/구</option>
          {selectableSigungus.map((selectableSigungu) => (
            <option key={selectableSigungu}>{selectableSigungu}</option>
          ))}
        </Select>
      </div>
      {index > 0 && <MinusBtn className={st.minusBtn} onClick={() => mob.deleteBranch(index)} />}
    </div>
  );
});

export default EstablishNewBranch;
