import { get, post } from '@common/module/httpRequest';
import adminModel from '@model/Corp/AdminSetting/adminModel';
import corpModel from '@model/Corp/corpModel';
import { runInAction } from 'mobx';

import { Id, LoadFrameRs } from './corpService.interface';

class CorpService {
  async loadFrame(id: Id) {
    const res = await get<LoadFrameRs>(`/companyRegister/${id}/frame`);

    runInAction(() => {
      corpModel.company.companyName = res.data?.companyName || '';
      corpModel.company.companyAddress = res.data?.companyAddress || '';
      corpModel.company.corpType = res.data?.corpType || '';
      corpModel.company.prefixedYn = res.data?.prefixedYn ?? null;
      corpModel.companyRegisterStatus = res.data?.companyRegisterStatus || null;
      corpModel.stockholderListFileRegisterStatus = res.data?.stockholderListFileRegisterStatus || false;
      corpModel.companies = res.data?.companies.map((company) => {
        return {
          companyName: company?.companyName || '',
          companyId: String(company?.companyId) || '',
          companyAddress: company?.companyAddress || '',
          isDemoYn: company?.isDemoYn || false,
          status: company?.status || null,
          liquidationYn: company?.liquidationYn || false,
        };
      });
      corpModel.menu.businessRegistration.useYn = res.data?.menu?.businessRegistration.useYn || false;
      corpModel.menu.businessRegistration.tagName = res.data?.menu?.businessRegistration.tagName || '';
      corpModel.menu.articlesOfAssociationTagName = res.data?.menu?.articlesOfAssociationTagName || '';
      corpModel.menu.bookkeepingContractTagName = res.data?.menu?.bookkeepingContractTagName || '';
      corpModel.menu.copyFileTagName = res.data?.menu?.copyFileTagName || '';
      corpModel.menu.stockholderTagName = res.data?.menu?.stockholderTagName || '';
      corpModel.menu.corporateTaxTagName = res.data?.menu?.corporateTaxTagName || '';
      corpModel.menu.valueAddedTaxTagName = res.data?.menu?.valueAddedTaxTagName || '';
      corpModel.menu.internetAssociateTagName = res.data?.menu?.internetAssociateTagName || '';
      corpModel.menu.flexLineTagName = res.data?.menu?.flexLineTagName || '';
      corpModel.menu.dashboardTagName = res.data?.menu?.dashboardTagName || '';
      corpModel.menu.agendaTagName = res.data?.menu?.agendaTagName || '';
      corpModel.alliancePopupShowYn = res.data?.alliancePopupShowYn || false;
      corpModel.acctFirmCode = res.data?.acctFirmCode || '';
      corpModel.companyRegisterStatusError = {
        title: res.data?.companyRegisterStatusError?.title || '',
        explain: res.data?.companyRegisterStatusError?.explain || '',
      };
      corpModel.notification = {
        title: res.data?.notification?.title || '',
        content: res.data?.notification?.content || '',
        link: res.data?.notification?.link || '',
        shouldShowPopup: res.data?.notification?.shouldShowPopup || false,
      };
      adminModel.setAuthority(res.data.authority);
    });
  }
  alliancePopupClose() {
    if (corpModel?.alliancePopupShowYn) {
      corpModel.alliancePopupShowYn = false;

      void post(`/company/${corpModel.id}/popup/close?popupCategory=ALLIANCE`);
    }
  }
  notificationPopupClose() {
    if (corpModel.notification?.shouldShowPopup) {
      corpModel.notification.shouldShowPopup = false;

      void post(`/company/${corpModel.id}/popup/close?popupCategory=MAIN_NOTIFICATION`);
    }
  }
}

export default new CorpService();
