import corpModel from '@model/Corp/corpModel';
import { makeObservable, observable } from 'mobx';

interface SideLinkType {
  type: string;
  menuName: string;
  path: string;
  // 해당 링크가 포함하는 상위 경로 (basePath에 포함되는 대상까지 모두 해당 사이드바가 하이라이트됨)
  basePath?: string;
  // 로그인하지 않은 사용자들을 위한 경로
  publicLink?: string;
  tag?: string;
  boxTag?: string;
  // 항상 활성 상태로 보여줄지 여부
  isActiveLabel?: boolean;
}

interface SideLinkGroup {
  title: string;
  pathName: string;
  iconType: string;
  // 노출될지 여부
  condition?: boolean;
  isActiveLabel?: boolean;
  links: SideLinkType[];
}

class SideVm {
  companyName = '';
  businessRegistrationActiveYn = false;
  awsCreditActiveYn = false;
  internetAssociateActiveYn = false;
  flexLineActiveYn = false;
  constructor() {
    makeObservable(this, {
      companyName: observable,
      businessRegistrationActiveYn: observable,
      awsCreditActiveYn: observable,
      internetAssociateActiveYn: observable,
      flexLineActiveYn: observable,
    });
  }

  get company() {
    return corpModel.company;
  }

  get companies() {
    return corpModel.companies;
  }

  get corpType() {
    return corpModel.company.corpType;
  }

  get useYn() {
    return !!corpModel.menu.businessRegistration.useYn;
  }

  get tagName() {
    return corpModel.menu.businessRegistration.tagName;
  }

  get menu() {
    return corpModel.menu;
  }

  setCompanyName(name: string) {
    this.companyName = name;
  }

  setBusinessRegistrationActiveYn(value: boolean) {
    this.businessRegistrationActiveYn = value;
  }

  setAwsCreditActiveYn(value: boolean) {
    this.awsCreditActiveYn = value;
  }

  setInternetAssociateActiveYn(value: boolean) {
    this.internetAssociateActiveYn = value;
  }

  setFlexLineActiveYn(value: boolean) {
    this.flexLineActiveYn = value;
  }

  selectNavTitle = (link: string) => {
    switch (true) {
      case link === '/corp/articlesOfAssociation' ||
        link === '/corp/register' ||
        link === '/corp/accounting/businessRegistration' ||
        link.includes('/corp/seal'):
        return 'companyInfo';

      case link.includes('corp/stock'):
        return 'shareholder';

      case link.includes('corp/accounting/bookKeepingContract') ||
        link === '/corp/accounting/corporateTax' ||
        link === '/corp/accounting/vat':
        return 'tax';

      case link.includes('/corp/awsCredit') ||
        link.includes('/corp/alliance') ||
        link.includes('/corp/internetAssociate') ||
        link.includes('/corp/flexLine'):
        return 'contract';

      case link === '/corp/dashboard':
        return 'home';

      case link.includes('agenda'):
        return 'agenda';

      default:
        return null;
    }
  };

  navItems(isViewAll = false): SideLinkGroup[] {
    const items: SideLinkGroup[] = [
      {
        title: 'Home',
        pathName: 'home',
        iconType: 'Home',
        condition: !(this.corpType === '유한회사' || this.corpType === '유한책임회사'),
        links: [
          {
            type: 'DB',
            menuName: '한 눈에 보는 우리회사',
            path: '/corp/dashboard',
            boxTag: this.menu?.dashboardTagName,
          },
        ],
      },
      {
        title: '회사정보',
        pathName: 'companyInfo',
        isActiveLabel: this.businessRegistrationActiveYn,
        iconType: 'Info',
        condition: true,
        links: [
          {
            type: 'AOA',
            menuName: '정관',
            path: '/corp/articlesOfAssociation',
            tag: this.menu?.articlesOfAssociationTagName,
          },
          {
            type: 'CR',
            menuName: '등기부',
            path: '/corp/register',
            tag: this.menu?.copyFileTagName,
          },
          (this.useYn || isViewAll) && {
            type: 'BR',
            menuName: '사업자등록',
            path: '/corp/accounting/businessRegistration',
            isActiveLabel: this.businessRegistrationActiveYn,
            tag: this.tagName,
          },
          {
            type: 'SE',
            menuName: '법인인감',
            path: '/corp/seal',
            basePath: '/corp/seal',
          },
        ].filter((link) => link !== false) as SideLinkType[],
      },
      {
        title: '자동화 서비스',
        pathName: 'agenda',
        iconType: 'Menu',
        condition: !(this.corpType === '유한회사' || this.corpType === '유한책임회사'),
        links: [
          {
            type: 'AG',
            menuName: '주주총회/이사회/등기',
            path: '/corp/agenda/progress',
            basePath: '/corp/agenda',
            boxTag: this.menu?.agendaTagName,
          },
        ],
      },
      {
        title: '주주/주식',
        pathName: 'shareholder',
        iconType: 'Graph',
        condition: !(this.corpType === '유한회사' || this.corpType === '유한책임회사'),
        links: [
          {
            type: 'SH',
            menuName: '주주명부',
            path: '/corp/stock/home',
            basePath: '/corp/stock',
            tag: this.menu?.stockholderTagName,
          },
        ],
      },
      {
        title: '세금',
        pathName: 'tax',
        iconType: 'Money',
        condition: true,
        links: [
          {
            type: 'BC',
            menuName: '기장계약 with 회계사무소',
            path: '/corp/accounting/bookKeepingContract',
            tag: this.menu?.bookkeepingContractTagName,
          },
          {
            type: 'VAT',
            menuName: '부가세',
            path: '/corp/accounting/vat',
            tag: this.menu?.valueAddedTaxTagName,
          },
          {
            type: 'CT',
            menuName: '법인세',
            path: '/corp/accounting/corporateTax',
            tag:
              this.menu?.corporateTaxTagName !== '신고완료(국세)' &&
              this.menu?.corporateTaxTagName !== '신고완료(지방세)'
                ? this.menu?.corporateTaxTagName
                : undefined,
            boxTag:
              this.menu?.corporateTaxTagName === '신고완료(국세)' ||
              this.menu?.corporateTaxTagName === '신고완료(지방세)'
                ? this.menu?.corporateTaxTagName
                : undefined,
          },
        ],
      },
      {
        title: '스탠바이가 보증하는 특별혜택',
        pathName: 'contract',
        iconType: 'Star',
        condition: true,
        isActiveLabel: this.awsCreditActiveYn || this.internetAssociateActiveYn || this.flexLineActiveYn,
        links: [
          {
            type: 'IT',
            menuName: '기업용 인터넷·전화 캐시백',
            path: '/corp/internetAssociate',
            publicLink: '/guest/internetAssociate',
            boxTag: this.menu?.internetAssociateTagName,
            isActiveLabel: this.internetAssociateActiveYn,
          },
          {
            type: 'AC',
            menuName: '클라우드 지원금 270만원',
            publicLink: '/guest/awsCredit',
            path: '/corp/awsCredit',
            isActiveLabel: this.awsCreditActiveYn,
          },
          {
            type: 'FL',
            menuName: 'KT 랜섬웨어 방화벽',
            path: '/corp/flexLine',
            publicLink: '/guest/flexLine',
            boxTag: this.menu?.flexLineTagName,
            isActiveLabel: this.flexLineActiveYn,
          },
        ],
      },
    ];

    return items.filter((item) => item.condition !== false);
  }
}

export default new SideVm();
