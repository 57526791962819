import NewModalModel from '@model/newModalModel';
import companyRegistrationService from '@service/companyRegistrationService';
import { observable } from 'mobx';
import ReactGA from 'react-ga4';

class RegisterPopupVm {
  constructor() {
    this.state = observable({
      loadingYn: false,
    });
  }
  async registerBtn(_param) {
    try {
      this.state.loadingYn = true;
      await companyRegistrationService.register(_param);
      NewModalModel.closeModal();
      //마케팅 구글에널리틱스 관련 코드
      ReactGA.gtag('event', 'complete_corp_registration');
      this.state.loadingYn = false;
    } catch (error) {
      this.state.loadingYn = false;
    }
  }
}

export default new RegisterPopupVm();
