import formDataToJson from '@common/module/submit';
import NewModalModel from '@model/newModalModel';
import portalModel from '@model/portalModel';
import { Alert } from '@src/model/newDialogModel';
import { observable, runInAction } from 'mobx';

import AoAPopup from './AoAPopup/AoAPopup';
import CompanyNameSearch from './CompanyNameSearch/CompanyNameSearch';
import RegisterPopup from './RegisterPopup/RegisterPopup';
import ShareholderListPopup from './ShareholderListPopup/ShareholderListPopup';

class CompanyRegistrationVm {
  constructor() {
    this.state = observable({
      action: false,
      companyNameSearch: false,
      company: {
        companyType: '',
        companyName: '',
        address: '',
        office: '',
        registerNum: '',
      },
    });
  }
  async submit(e) {
    e.preventDefault();
    if (formDataToJson(e.target).error) {
      return;
    }

    const param = new FormData(e.target);
    //파일업로드 validation
    const regExp = /\[\d+\]/g;
    const _key = [];

    for (const [key, value] of param) {
      _key.push(key.replace(regExp, ''));
    }

    if (!_key.includes('stockholderFiles')) {
      await Alert('주주명부를 업로드하세요.');

      return;
    }
    if (!_key.includes('aoaFiles')) {
      await Alert('정관을 업로드하세요.');

      return;
    }
    if (!_key.includes('businessRegistrationFile')) {
      await Alert('사업자등록증을 업로드하세요.');

      return;
    }

    NewModalModel.openModal(
      <RegisterPopup
        param={param}
        companyName={this.state.company.companyName}
        companyAddress={this.state.company.address}
      />,
    );
  }
  get companyNameSearch() {
    return this.state.companyNameSearch;
  }
  openCompanyNameSearch() {
    NewModalModel.openModal(<CompanyNameSearch companyData={this.state.company} />);
  }
  shareholderListPopup() {
    runInAction(() => {
      portalModel.content = <ShareholderListPopup />;
      portalModel.closeBtnDarkYn = true;
    });
  }
  aoaPopup() {
    runInAction(() => {
      portalModel.content = <AoAPopup />;
      portalModel.closeBtnDarkYn = true;
    });
  }
}

export default CompanyRegistrationVm;
