import { useLoadFlexLineQuery } from './queries';

const useFlexLine = (corpId: string) => {
  const flexlineQueryResult = useLoadFlexLineQuery(corpId);

  return {
    flexlineQueryResult,
  };
};

export default useFlexLine;
