import formDataToJson from '@common/module/submit';
import bookKeepingContractModel from '@model/Corp/BookKeepingContract/bookKeepingContractModel';
import bookKeepingContractService from '@service/Corp/BookKeepingContract/bookKeepingContractService';
import { observable, runInAction, toJS } from 'mobx';
import portalModel from '@model/portalModel';
import corpModel from '@model/Corp/corpModel';
import { _alert, _confirm } from '@model/dialogModel';

class ElectronicSignaturePopupVm {
  constructor() {
    this.state = observable({
      loadingYn: false,
    });
  }
  load() {
    bookKeepingContractService.electronicSignature();
  }
  get bookkeepingContractLink() {
    return bookKeepingContractModel.bookkeepingContractLink;
  }
  get corpId() {
    return corpModel.id;
  }
  async requestAgree(e) {
    e.preventDefault();

    const formData = formDataToJson(e.target);

    // sealExecutiveId로 시작하는 에러 요소가 있는 경우 경고 메시지 표시
    if (formData.errorElementsName.some((name) => name.startsWith('sealExecutiveId'))) {
      // data-executiveName 속성을 가진 요소에서 executiveName 값을 가져옴
      const sealExecutiveNames = Array.from(e.target.querySelectorAll('[data-executiveName][data-error="error"]')).map(
        (element) => element.getAttribute('data-executiveName'),
      );

      void _alert(`${sealExecutiveNames.join(', ')}님의 인감을 선택하세요.`);
    }

    if (formData.error) {
      return;
    }

    this.state.loadingYn = true;
    try {
      await bookKeepingContractService.agreeElectronicSignature();
      this.state.loadingYn = false;
      runInAction(() => {
        portalModel.title = null;
        portalModel.content = null;
      });
      await _alert('전자서명이 완료되었어요.', '서명된 PDF파일을 다운로드 받을 수 있어요.');
      window.location.reload();
    } catch (error) {
      this.state.loadingYn = false;
    }
  }
}

export default new ElectronicSignaturePopupVm();
