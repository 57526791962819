import PageMainTitle from '@commonComponents/PageMainTitle';
import NewModalModel from '@model/newModalModel';
import UserSetting from '@pages/UserSetting/UserSetting';
import { FlexLineStep } from '@service/FlexLine/FlexLineService.interface';
import { Star } from '@standby/dike_ui/assets/icon/fill';
import { Button, Typography } from '@standby/dike_ui/components/atoms';
import { CopyButton } from '@standby/dike_ui/components/molecules';
import color from '@standby/dike_ui/design-token/color.module.scss';
import clsx from 'clsx';
import { runInAction } from 'mobx';
import { observer } from 'mobx-react';
import { Link } from 'react-router-dom';

import st from './style.module.scss';

const openUserSetting = () => {
  runInAction(() => {
    NewModalModel.openModal(<UserSetting activeTab="약관동의" />);
  });
};

const AfterApplicationSubmission = observer(({ step, corpId }: { step: FlexLineStep; corpId: string }) => {
  return (
    <div className={st.afterApplicationSubmission}>
      <PageMainTitle icon={<Star width="24" height="24" fill={color.primary800} />} title="KT 랜섬웨어 방화벽" />
      {(step === 'APPLICATION_RECEIVED' || step === 'ACTIVATION_RECEIVED') && (
        <div className={st.step}>
          <Typography fontType="Detail-12M" className={st.badge}>
            상담접수완료
          </Typography>
          <Typography fontType="Title-24B" className={st.title}>
            상담신청이 접수되었어요.
          </Typography>
          <Typography fontType="Body-15R" className={st.content}>
            KT 대리점에서 캐시백 상담전화를 드릴 거에요. (1 영업일 내, 010 번호로 전화를 드려요)
          </Typography>
        </div>
      )}
      {step === 'ACTIVATION_COMPLETED' && (
        <div className={st.step}>
          <Typography fontType="Detail-12M" className={st.badge}>
            개통완료
          </Typography>
          <Typography fontType="Title-24B" className={st.title}>
            개통이 완료되었어요.
          </Typography>
          <Typography fontType="Body-15R" className={st.content}>
            신청하신 플렉스라인 개통이 완료되었어요. 캐시백 지급 등 문의사항이 있다면, [스탠바이 전용 KT 콜센터]를
            이용해보세요!
          </Typography>
        </div>
      )}
      <div className={st.mainContent}>
        {step === 'ACTIVATION_COMPLETED' && (
          <div className={clsx(st.row, st.rowSpaceBetween)}>
            <div className={st.listContent}>
              <Typography fontType="Title-18M" className={st.title}>
                스탠바이 전용 KT 콜센터
              </Typography>
              <Typography fontType="Body-15R" className={st.sub}>
                플렉스라인 문의사항이 있으신가요? 바로 전화해보세요!
              </Typography>
              <ul className={st.list}>
                <li>
                  <Typography fontType="Body-14R">① 우측 [복사하기] 버튼을 클릭하면, 전화번호가 복사되어요.</Typography>
                </li>
                <li>
                  <Typography fontType="Body-14R">
                    ② 통화 시 <em>“스탠바이랩 고객 입니다.”</em>라고 꼭 말해주세요.
                  </Typography>
                </li>
              </ul>
            </div>
            <div className={clsx(st.copy, st.telephone)}>
              <Typography fontType="Body-16M" className={st.title}>
                <em>오전 10</em>시부터 <em>오후 6시</em>까지! 전화 시 바로 상담이 가능해요
              </Typography>
              <div className={st.copyArea}>
                <Typography fontType="Body-14R" className={st.copyText}>
                  1588-9790
                </Typography>
                <CopyButton direction="leftUp" copyText="1588-9790">
                  <Button type="button" color="primary400">
                    복사하기
                  </Button>
                </CopyButton>
              </div>
            </div>
          </div>
        )}
        <div className={clsx(st.row, st.rowSpaceBetween)}>
          <div className={st.listContent}>
            <Typography fontType="Title-18M" className={st.title}>
              혜택 소개하기
            </Typography>
            <Typography fontType="Body-15R" className={st.sub}>
              지인분께 KT 랜섬웨어 방화벽 캐시백을 받는 기회를 나눠보세요!
            </Typography>
            <ul className={st.list}>
              <li>
                <Typography fontType="Body-14R">① 우측 [복사하기] 버튼을 클릭하면, 링크가 복사되어요.</Typography>
              </li>
              <li>
                <Typography fontType="Body-14R">② 혜택을 알리고 싶은 지인분께 링크를 전달하세요!</Typography>
              </li>
            </ul>
          </div>
          <div className={st.copy}>
            <Typography fontType="Body-16M" className={st.title}>
              KT 랜섬웨어 방화벽 소개 링크
            </Typography>
            <div className={st.copyArea}>
              <Typography fontType="Body-14R" className={st.copyText}>
                app.standby.kr/guest/flexLine
              </Typography>
              <CopyButton direction="leftUp" copyText="app.standby.kr/guest/flexLine">
                <Button type="button" color="primary400">
                  복사하기
                </Button>
              </CopyButton>
            </div>
          </div>
        </div>
        <div className={st.row}>
          <Typography fontType="Title-18M" className={st.title}>
            제휴혜택 더 알아보기
          </Typography>
          <Typography fontType="Body-15R" className={st.sub}>
            오직 스탠바이 법인에게만 제공되는 특별한 제휴 혜택!
          </Typography>
          <ul className={st.advertisementCards}>
            <li>
              <Link to={`/corp/alliance?corp=${corpId}`}>
                <img src="/images/internetAssociate_advertisement1.png" />
              </Link>
            </li>
            <li>
              <button type="button" onClick={() => openUserSetting()}>
                <img src="/images/internetAssociate_advertisement2.png" />
              </button>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
});

export default AfterApplicationSubmission;
