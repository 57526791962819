import Loading from '@commonComponents/Loading/Loading';
import NewModalModel from '@model/newModalModel';
import ScrollArea from '@src/pages/common/ScrollArea';
import { MarkBang } from '@standby/dike_ui/assets/icon/fill';
import { Button, Label, SelectField, TextField, Typography, UnorderedList } from '@standby/dike_ui/components/atoms';
import {
  ErrorValidation,
  ModalContentWrapper,
  ModalLayout,
  ModalTitle,
  SelectionControl,
} from '@standby/dike_ui/components/molecules';
import color from '@standby/dike_ui/design-token/color.module.scss';
import { useObserver } from 'mobx-react';
import { useEffect, useState } from 'react';

import st from './CompanyNameSearch.module.scss';
import CompanyNameSearchVm from './CompanyNameSearchVm';

function CompanyNameSearch(props) {
  const [vm] = useState(new CompanyNameSearchVm());

  useEffect(() => {
    vm.load();
  }, []);

  return useObserver(() => (
    <ModalLayout>
      <ModalTitle title="한글 회사 이름 입력" onClickClose={() => NewModalModel.closeModal()} />
      <ModalContentWrapper>
        <div className={st.companyNameSearch}>
          {vm.state.loadingYn && <Loading />}
          <form onSubmit={(e) => vm.search(e)} autoComplete="off" className={st.form}>
            <div className={st.row}>
              <Label className={st.title}>회사 본점 소재지</Label>
              <div className={st.content}>
                <div className={st.contentRow}>
                  <SelectField
                    name="광역자치단체"
                    className={st.select}
                    value={vm.state.광역자치단체}
                    onChange={(e) => vm.set광역자치단체(e)}
                    error={
                      <ErrorValidation
                        name="광역자치단체"
                        value={vm.state.광역자치단체}
                        errorCase={{
                          required: '시/도를 선택해주세요.',
                        }}
                      />
                    }
                  >
                    <option value="">시/도</option>
                    {vm.광역자치단체s.map((item, idx) => (
                      <option value={item.name} key={idx}>
                        {item.name}
                      </option>
                    ))}
                  </SelectField>
                  {vm.state.광역자치단체 &&
                    vm.state.광역자치단체 !== '서울특별시' &&
                    vm.state.광역자치단체 !== '세종특별자치시' && (
                      <SelectField
                        name="시군구"
                        className={st.select}
                        value={vm.state.시군구}
                        onChange={(e) => vm.set시군구(e)}
                        disabled={!vm.state.광역자치단체}
                        error={
                          vm.state.광역자치단체 ? (
                            <ErrorValidation
                              name="시군구"
                              value={vm.state.시군구}
                              errorCase={{
                                required: '시/군/구를 선택해주세요.',
                              }}
                            />
                          ) : null
                        }
                      >
                        <option value="">시/군/구</option>
                        {vm.시군구List.map((item, idx) => (
                          <option value={item} key={idx}>
                            {item}
                          </option>
                        ))}
                      </SelectField>
                    )}
                </div>
              </div>
            </div>
            <div className={st.row}>
              <Label className={st.title}>한글 회사이름</Label>
              <div className={st.content}>
                <div className={st.contentRow}>
                  <TextField
                    name="companyName"
                    style={{ width: '332px' }}
                    value={vm.state.companyName}
                    onChange={(e) => vm.setCompanyName(e)}
                    error={
                      <ErrorValidation
                        name="companyName"
                        value={vm.state.companyName}
                        errorCase={{
                          required: '한글 회사이름을 입력해주세요.',
                        }}
                      />
                    }
                  />
                  <Button color="primary400" variant="outlined" type="submit" className={st.submitBtn}>
                    조회
                  </Button>
                </div>
                <Typography fontType="Detail-12M" className={st.explain}>
                  '주식회사'를 제외한 나머지 법인명을 입력하세요.
                </Typography>
              </div>
            </div>
          </form>
          {vm.irosStatusType && (
            <form onSubmit={(e) => vm.submitNextBtn(e, props.companyData)}>
              <div className={st.companyListBox}>
                {vm.displaySearchResults === 'None' && ( //검색 리스트가 없는 경우
                  <div className={st.companyListEmpty}>
                    <MarkBang width={20} height={20} fill={color.siRed500} />
                    <div className={st.emptyInfo}>
                      <Typography fontType="Body-15M" className={st.empty}>
                        위 주소에서는 <span className={st.bold}>"{vm.searchKeyword}"</span>(이)가 등록되어있지 않아요.
                      </Typography>
                      <UnorderedList className={st.emptyExplainBox}>
                        <li className={st.emptyExplain}>
                          회사이름이 정확한지 확인하세요.
                          <Typography fontType="Detail-12M" className={st.sub}>
                            예) “홍길동컴퍼니” 검색 시<br />{' '}
                            <span style={{ marginLeft: '16px' }}>-&gt; 홍길동(X) | 홍길동컴퍼니(O)</span>
                          </Typography>
                        </li>
                        <li className={st.emptyExplain}>
                          본점 소재지가 정확한지 확인하세요.
                          <Typography fontType="Detail-12M" className={st.sub}>
                            예) 본점 소재지 (O) | 지점 소재지(X)
                          </Typography>
                        </li>
                      </UnorderedList>
                    </div>
                  </div>
                )}
                {vm.displaySearchResults === 'Results' && ( //검색 리스트가 있는 경우
                  <div className={st.companyList}>
                    <ScrollArea className={st.scrollArea} thumbWidth={4} thumbColor="#D2D4D7">
                      <div className={st.companyInfoList}>
                        {vm.searchResults.map((item, idx) => {
                          return (
                            <SelectionControl
                              name="chooseCompany"
                              key={idx}
                              checked={vm.state.chooseCompanyIdx === String(idx)}
                              value={idx}
                              onChange={(e) => vm.chooseCompany(e)}
                              subText={item.address}
                              controlType="radio"
                            >
                              {item.companyName}
                            </SelectionControl>
                          );
                        })}
                      </div>
                    </ScrollArea>
                  </div>
                )}
                {vm.displaySearchResults === 'IrosError' && ( //인터넷 등기소 오류
                  <div className={st.companyList}>
                    <ScrollArea className={st.scrollArea} thumbWidth={4} thumbColor="#D2D4D7">
                      <div className={st.companyInfoList}>
                        <SelectionControl
                          name="chooseIrosFailCompany"
                          checked={vm.state.chooseIrosFailCompany}
                          value={vm.state.chooseIrosFailCompany}
                          onChange={(e) => vm.chooseIrosFailCompany(e)}
                          subText={`${vm.state.광역자치단체} ${vm.state.시군구}`}
                          controlType="radio"
                        >
                          {vm.state.companyName}
                        </SelectionControl>
                      </div>
                    </ScrollArea>
                  </div>
                )}
              </div>
              <div>
                {vm.displaySearchResults === 'Results' && (
                  <ErrorValidation
                    name="chooseCompany"
                    value={vm.state.chooseCompanyIdx}
                    errorCase={{ required: '회사를 선택해주세요.' }}
                  />
                )}
                {vm.displaySearchResults === 'IrosError' && (
                  <ErrorValidation
                    name="chooseIrosFailCompany"
                    value={vm.state.chooseIrosFailCompany}
                    errorCase={{ required: '회사를 선택해주세요.' }}
                  />
                )}
              </div>
              {vm.displaySearchResults !== 'None' && (
                <div className={st.nextBtnArea}>
                  <Button type="submit" className={st.nextBtn} color="primary400">
                    다음
                  </Button>
                </div>
              )}
            </form>
          )}
        </div>
      </ModalContentWrapper>
    </ModalLayout>
  ));
}

export default CompanyNameSearch;
