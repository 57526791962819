import { AxiosError } from 'axios';

/**
 * Sentry에서 무시되는 에러
 */
export class IgnoredError extends Error {
  constructor(message: string) {
    super(message);
    this.name = 'IgnoredError';
  }
}

/**
 * Sentry에 기록되는 Axios 에러
 * Axios Error를 래핑해서 name을 변경해 Sentry에 기록되도록 한다.
 */
export class RecordedAxiosError extends AxiosError {
  originalAxiosError: object;

  constructor(error: AxiosError) {
    super(error.message, error.code, error.config, error.request, error.response);
    // "AxiosError" 가 들어가면 ignore 되므로 이름을 변경해준다.
    this.name = 'RecordedError | [Axios 오류 기록]';
    this.originalAxiosError = error.toJSON();
  }

  getTrimmedErrorData() {
    return {
      ...this._clonePlainObject(this),
      name: this.name,
      message: this.message,
      code: this.code,
      status: this.response?.status || '',
      statusText: this.response?.statusText || '',
      requestURL: this.request?.responseURL || '',
      responseData: this.response?.data || '',
      config: this._clonePlainObject(this.config),
      request: {
        ...this._clonePlainObject(this.request),
        ...this._extractXHRData(this.request),
      },
      response: this._clonePlainObject(this.response),
      originalAxiosError: this.originalAxiosError,
    };
  }

  _clonePlainObject(obj: unknown) {
    try {
      return JSON.parse(JSON.stringify(obj));
    } catch {
      return {};
    }
  }

  _extractXHRData(xhr: XMLHttpRequest) {
    try {
      if (!xhr || xhr instanceof XMLHttpRequest === false) return {};

      return {
        status: xhr.status,
        statusText: xhr.statusText,
        response: xhr.response,
        responseText: xhr.responseText,
        responseURL: xhr.responseURL,
        responseType: xhr.responseType,
        responseXML: xhr.responseXML,
        readyState: xhr.readyState,
        timeout: xhr.timeout,
        withCredentials: xhr.withCredentials,
      };
    } catch {
      return {};
    }
  }
}
