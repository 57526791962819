import { useObserver } from 'mobx-react';
import queryString from 'query-string';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import st from './AgendaDashboard.module.scss';
import vm from './AgendaDashboardVm';
import Agendas from './Agendas/Agendas';
import Aoa from './Aoa/Aoa';
import BoardOfDirectors from './BoardOfDirectors/BoardOfDirectors';
import Capital from './Capital/Capital';
import Customer from './Customer/Customer';
import Decision from './Decision/Decision';
import Header from './Header/Header';
import Memo from './Memo/Memo';
import PersonInCharge from './PersonInCharge/PersonInCharge';
import Registration from './Registration/Registration';
import Rnumber from './Rnumber/Rnumber';
import Schedule from './Schedule/Schedule';
import ShareholderList from './ShareholderList/ShareholderList';
import TopWarning from './TopWarning/TopWarning';

function AgendaDashboard() {
  const location = useLocation();
  const rNumber = queryString.parse(location.search).rNumber as string;
  const cssn = queryString.parse(location.search).cssn as string;

  useEffect(() => {
    vm.load(rNumber, cssn);
  }, []);

  return useObserver(() =>
    vm.accessRoute ? (
      <div className={st.agendaDashboard}>
        <Header />
        {vm.agendaProgress.company.capital !== 0 && (
          <div className={st.agendaBody}>
            <TopWarning />
            <div className={st.horizontal}>
              <Rnumber />
              <PersonInCharge />
            </div>
            <div className={st.horizontal}>
              <Agendas />
            </div>
            <div className={st.horizontal}>
              <Decision />
              <Customer />
            </div>
            <div className={st.horizontal}>
              <Registration />
            </div>
            <div className={st.horizontal}>
              <BoardOfDirectors />
              <Capital />
            </div>
            <div className={st.horizontal}>
              <ShareholderList />
              <Aoa />
            </div>
            <div className={st.horizontal}>
              <Memo />
            </div>
            <div className={st.horizontal}>
              <Schedule />
            </div>
          </div>
        )}
      </div>
    ) : (
      <></>
    ),
  );
}

export default AgendaDashboard;
