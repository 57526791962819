import { useObserver } from 'mobx-react';
import { Navigate, Route, Routes } from 'react-router-dom';

import AwsCredit from './AwsCredit/AwsCredit';
import FlexLine from './FlexLine';
import InternetAssociate from './InternetAssociate/InternetAssociate';
import SecurityCheck from './SecurityCheck/SecurityCheck';

function Guest() {
  return useObserver(() => (
    <Routes>
      <Route path="*" element={<Navigate to="/guest/awsCredit" replace={true} />} />
      <Route path="/security" element={<SecurityCheck />} />
      <Route path="/awsCredit" element={<AwsCredit />} />
      <Route path="/internetAssociate" element={<InternetAssociate />} />
      <Route path="/flexLine" element={<FlexLine />} />
    </Routes>
  ));
}

export default Guest;
