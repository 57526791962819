import formDataToJson from '@common/module/submit';
import corpModel from '@model/Corp/corpModel';
import businessRegistrationModel from '@model/Corp/BusinessRegistration/businessRegistrationModel';
import businessRegistrationService from '@service/Corp/BusinessRegistration/businessRegistrationService';
import { observable, runInAction } from 'mobx';
import { _alert } from '@model/dialogModel';
import ReactGA from 'react-ga4';

class InformationInputVm {
  constructor() {
    this.state = observable({
      loadingYn: false,
    });
  }
  get corpId() {
    return corpModel.id;
  }
  async load() {
    await businessRegistrationService.loadInfoInput();
    if (this.typeOfBusinesses.length === 0) {
      this.addBusiness();
    }
    //마케팅 구글에널리틱스 관련 코드
    let openIdList = [];
    if (localStorage.getItem('businessRegistrationOpenId')) {
      openIdList = localStorage.getItem('businessRegistrationOpenId').split(',');
      if (!openIdList.includes(this.corpId)) {
        ReactGA.gtag('event', 'view_start_page');
        openIdList.push(this.corpId);
      }
      localStorage.setItem('businessRegistrationOpenId', openIdList.join());
    } else {
      ReactGA.gtag('event', 'view_start_page');
      localStorage.setItem('businessRegistrationOpenId', this.corpId);
    }
  }
  async submit(e) {
    e.preventDefault();
    if (formDataToJson(e.target).error) {
      return;
    } else {
      let param = new FormData(e.target);
      //파일 업로드 validation
      const regExp = /\[\d+\]/g;
      let originKey = [];
      let newKey = [];
      for (let [key, value] of param) {
        originKey.push(key);
        newKey.push(key.replace(regExp, ''));
      }

      for (let i = 0; i < businessRegistrationModel.representatives.length; i++) {
        let representative = businessRegistrationModel.representatives[i];
        if (representative.representativeIDFiles.length === 0) {
          if (!originKey.some((elem) => elem.includes(`representatives[${i}].uploadFiles`))) {
            await _alert('신분증 앞뒤면을 업로드하세요.');
            window.scrollTo({ top: '862', behavior: 'smooth' });
            return;
          }
        }
      }

      if (businessRegistrationModel.corporateLeaseAgreementFiles.length === 0) {
        if (!newKey.includes('corporateLeaseAgreementFiles.uploadFiles')) {
          await _alert('임대차계약서를 업로드하세요.');
          window.scrollTo({ top: '1145', behavior: 'smooth' });
          return;
        }
      }

      try {
        this.state.loadingYn = true;
        await businessRegistrationService.infoInput(param);
        //마케팅 구글에널리틱스 관련 코드
        ReactGA.gtag('event', 'complete_business_registration_form');
        this.state.loadingYn = false;
        await _alert(
          '사업자등록 정보입력이 잘 접수되었어요.',
          '이제 회계 사무소가 검토를 시작해요. 검토를 빠르게 마친 후, 회계 사무소 담당자가 대표님께 연락드릴게요.',
        );
        window.location.reload();
      } catch (err) {
        this.state.loadingYn = false;
      }
    }
  }
  async tempSubmit(_data) {
    let param = new FormData(_data);
    await businessRegistrationService.infoInputTemp(param);
    await _alert('임시저장 되었어요.');
  }
  get businessRegistrationStatuses() {
    return businessRegistrationModel.businessRegistrationStatuses;
  }
  get statusType() {
    return businessRegistrationModel.businessRegistrationStatuses[
      businessRegistrationModel.businessRegistrationStatuses.length - 1
    ]?.status;
  }
  get companyName() {
    return businessRegistrationModel.companyName;
  }
  get establishedAt() {
    return businessRegistrationModel.establishedAt;
  }
  get dateOfBusinessCommencement() {
    return businessRegistrationModel.dateOfBusinessCommencement;
  }
  setDateOfBusinessCommencement(e) {
    let date = null;
    if (e) {
      const year = e.getFullYear();
      const month = ('0' + (e.getMonth() + 1)).slice(-2);
      const day = ('0' + e.getDate()).slice(-2);

      date = year + '-' + month + '-' + day;
    }

    runInAction(() => {
      businessRegistrationModel.dateOfBusinessCommencement = date;
    });
  }
  checkDateOfBusinessCommencement() {
    const establishedAt = new Date(this.establishedAt).getTime();
    const dateOfBusinessCommencement = new Date(this.dateOfBusinessCommencement).getTime();
    if (establishedAt > dateOfBusinessCommencement) {
      return false;
    } else {
      return true;
    }
  }
  get representatives() {
    return businessRegistrationModel.representatives;
  }
  get corporateLeaseAgreementFiles() {
    return businessRegistrationModel.corporateLeaseAgreementFiles;
  }
  get majorShareholderRelationships() {
    return businessRegistrationModel.majorShareholderRelationships;
  }
  setRelationship(e, idx, idx2) {
    runInAction(() => {
      businessRegistrationModel.majorShareholderRelationships[idx].shareholders[idx2].relationship = e.target.value;
    });
  }
  get selectableBusinessPurposes() {
    return businessRegistrationModel.selectableBusinessPurposes;
  }
  get typeOfBusinesses() {
    return businessRegistrationModel.typeOfBusinesses;
  }
  setBusinessPurpose(e, idx) {
    runInAction(() => {
      businessRegistrationModel.typeOfBusinesses[idx].businessPurpose = e.target.value;
    });
  }
  async addBusiness() {
    if (businessRegistrationModel.typeOfBusinesses.length < 5) {
      runInAction(() => {
        businessRegistrationModel.typeOfBusinesses.push({
          id: '',
          businessPurpose: '',
          description: '',
        });
      });
    } else {
      await _alert('사업자등록증에는 최대 5개까지 업종을 넣을 수 있어요!');
    }
  }
  deleteBusiness(idx) {
    runInAction(() => {
      businessRegistrationModel.typeOfBusinesses.splice(idx, 1);
    });
  }
  setDescription(e, idx) {
    runInAction(() => {
      businessRegistrationModel.typeOfBusinesses[idx].description = e.target.value;
    });
  }
  get permitFiles() {
    return businessRegistrationModel.permitFiles;
  }
  get bookkeepingServiceApplyYn() {
    return businessRegistrationModel.bookkeepingServiceApplyYn;
  }
  setBookkeepingServiceApplyYn(e) {
    runInAction(() => {
      businessRegistrationModel.bookkeepingServiceApplyYn = e.target.value === 'true';
    });
  }
  get hometaxDelegationAcceptanceYn() {
    return businessRegistrationModel.hometaxDelegationAcceptanceYn;
  }
  setHometaxDelegationAcceptanceYn(e) {
    runInAction(() => {
      businessRegistrationModel.hometaxDelegationAcceptanceYn = e.target.value === 'true';
    });
  }
  get corpType() {
    return corpModel.company.corpType;
  }
  get text() {
    const corpType = corpModel.company.corpType;
    switch (corpType) {
      case '주식회사':
        return '주주';
      case '유한회사':
      case '유한책임회사':
        return '사원';
      default:
        return '주주';
    }
  }
  getRelationshipPlaceholder(shareholderType) {
    switch (shareholderType) {
      case '국내_성인':
      case '해외_성인':
        return 'ex) ○○○의 배우자';
      case '국내_미성년자':
      case '해외_미성년자':
        return 'ex) ○○○의 자녀';
      case '펀드_투자조합':
        return 'ex) ○○○이 최대 출자자인 법인 (지분율 47%)';
      case '국내_법인':
      case '해외_법인':
        return 'ex) ○○○이 최대주주인 법인 (지분율 47%)';
      default:
        return '';
    }
  }
  get cssn() {
    return businessRegistrationModel.cssn;
  }
  get freeMonth() {
    return businessRegistrationModel.freeMonth;
  }
  get acctFirmCode() {
    return corpModel.acctFirmCode;
  }
}

export default new InformationInputVm();
