import { Alert, Confirm } from '@src/model/newDialogModel';
import { Error, FileList, FileUploadButton, FileUploadItem } from '@standby/dike_ui/components/atoms';
import clsx from 'clsx';
import { useObserver } from 'mobx-react';
import React, { useEffect, useState } from 'react';

import st from './style.module.scss';

interface FileUploadProps {
  attachmentFilesModel?: {
    id: number | string;
    downloadUrl?: string;
    fileName?: string;
  }[];
  multiple?: boolean;
  accept?: string;
  className?: string;
  style?: React.CSSProperties;
  name?: string;
  label?: string;
  errorText?: string;
  deleteFileName?: string;
  color?: 'primary' | 'secondary';
  title?: string;
}

const FileUpload: React.FC<FileUploadProps> = React.memo(function FileUpload({ multiple = true, ...props }) {
  const [uploadFiles, setUploadFiles] = useState<string[]>([]);

  useEffect(() => {
    setUploadFiles([]);
  }, [props.attachmentFilesModel]);

  const uploadFilesAdd = () => {
    if (multiple) {
      setUploadFiles([...uploadFiles, '']);
    } else {
      setUploadFiles(['']);
    }
  };

  const uploadFileDelete = async (idx: number) => {
    if (await Confirm('업로드한 파일을 삭제하시겠습니까?', '')) {
      const fileTmp = [...uploadFiles];

      fileTmp[idx] = 'removed';
      setUploadFiles(fileTmp);
    }
  };

  const uploadFileChange = async (e: React.ChangeEvent<HTMLInputElement>, idx: number) => {
    if (e.target.files) {
      if (props.accept) {
        const acceptList = props.accept.split(',');

        acceptList.forEach((item, idx) => {
          acceptList[idx] = item.replace(/\./g, '').trim().toLowerCase();
        });
        const splitFileExtenstion = e.target.files[0].name.split('.');

        const fileExtenstion = splitFileExtenstion[splitFileExtenstion.length - 1].toLowerCase();

        if (acceptList.includes(fileExtenstion)) {
          if (e.target.files[0].size > 104857600) {
            await Alert('파일 용량이 초과되어 정상적으로 업로드 할 수 없습니다.');
          } else {
            const fileTmp = [...uploadFiles];

            fileTmp[idx] = e.target.files[0].name;
            setUploadFiles(fileTmp);
          }
        } else {
          await Alert(`${acceptFileList()} 파일만 업로드 가능합니다.`);
        }
      } else {
        if (e.target.files[0].size > 104857600) {
          await Alert('파일 용량이 초과되어 정상적으로 업로드 할 수 없습니다.');
        } else {
          const fileTmp = [...uploadFiles];

          fileTmp[idx] = e.target.files[0].name;
          setUploadFiles(fileTmp);
        }
      }
    }
  };
  const checkIdx = (idx: number) => {
    const currentIdx = idx;

    let preRemoveCnt = 0;

    uploadFiles.forEach((uploadFile, index) => {
      if (currentIdx > index) {
        if (uploadFile === 'removed' || uploadFile === '') {
          preRemoveCnt++;
        }
      }
    });

    return currentIdx - preRemoveCnt;
  };

  const acceptFileList = () => {
    const acceptFile = props.accept;

    let acceptList: string[] = [];

    let acceptFileList = '';

    if (acceptFile) {
      acceptList = acceptFile.split(',');
      acceptList.forEach((item, idx) => {
        acceptList[idx] = item.replace(/\./g, '').trim().toUpperCase();

        if (acceptList[idx] === 'DOCX') {
          acceptList[idx] = '워드';
        } else if (acceptList[idx] === 'HWP') {
          acceptList[idx] = '한글';
        }

        if (idx !== acceptList.length - 1) {
          acceptList[idx] = acceptList[idx].concat(' / ');
        }
      });
    }

    acceptFileList = acceptList.join().replace(/,/g, '');

    return acceptFileList;
  };

  return useObserver(() => (
    <div className={props.className} style={props.style}>
      <FileUploadButton
        color={props.color}
        id={`${props.name}${uploadFiles.length}`}
        labelOnClick={() => uploadFilesAdd()}
        title={props.title}
        label={props.label}
        useOnlyUI={true}
        style={{
          width: '100%',
        }}
      />
      {props.errorText && <Error>{props.errorText}</Error>}

      {uploadFiles.length !== 0 && (
        <FileList
          className={clsx(
            st.fileList,
            uploadFiles.filter((uploadFile) => uploadFile !== 'removed' && uploadFile !== '').length === 0
              ? st.fileListNone
              : '',
          )}
        >
          {uploadFiles && uploadFiles.length !== 0 && (
            <>
              {uploadFiles.map((uploadFile, idx) =>
                uploadFile === 'removed' ? (
                  <React.Fragment key={idx}></React.Fragment>
                ) : (
                  <List
                    uploadFile={uploadFile}
                    key={idx}
                    idx={idx}
                    name={props.name}
                    checkIdx={checkIdx}
                    uploadFileChange={uploadFileChange}
                    uploadFileDelete={uploadFileDelete}
                    multiple={multiple}
                  />
                ),
              )}
            </>
          )}
        </FileList>
      )}
    </div>
  ));
});

interface ListProps {
  multiple?: boolean;
  uploadFile: string;
  name?: string;
  idx: number;
  checkIdx(idx: number): number;
  uploadFileChange(e: React.ChangeEvent<HTMLInputElement>, idx: number): void;
  uploadFileDelete(idx: number): void;
  accept?: string;
}

const List = React.memo(function List(props: ListProps) {
  let _name: string | undefined = '';

  if (props.multiple) {
    if (props.uploadFile !== '') {
      _name = `${props.name}[${props.checkIdx(props.idx)}]`;
    } else {
      _name = undefined;
    }
  } else {
    _name = props.name;
  }

  return useObserver(() => (
    <FileUploadItem
      className={props.uploadFile === '' ? st.preUploadFile : ''}
      id={`${props.name}${props.idx + 1}`}
      name={_name}
      inputOnChange={(e) => props.uploadFileChange(e, props.idx)}
      accept={props.accept}
      fileName={props.uploadFile}
      onClickClose={() => props.uploadFileDelete(props.idx)}
    />
  ));
});

export default FileUpload;
